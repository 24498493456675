import React, { useEffect } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import RosterList from './RosterList';
import Login from './Login';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import { Box } from '@mui/material';
import HeaderMenu from './HeaderMenu';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import RegistrationForm from './registration/RegistrationForm';
import AdminPage from './AdminPage';
import About from './About';
import { useRosterType } from './data/RosterTypes';
import AccountingPage from './AccountingPage';
// import { ICrew } from './registration/ICrew';
import RegistrationForm from './registration/RegistrationForm';
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function HomePage() {
  const classes = useStyles();
  const [open] = React.useState(false);
  const [user] = useAuthState(firebase.auth());
  const [, setRosterType] = useRosterType();
  const location = window.location.href;
  const registration = location.includes('register') || location.includes('masters') || location.includes('juniors');

  useEffect(() => {
    if (location.includes('masters')) {
      setRosterType('masters');
    }
  }, [location, setRosterType]);
  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <Box displayPrint="none">
          <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
              {/* <IconButton
              edge="start"
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton> */}
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                {registration ? 'Everett Rowing Program Registration' : 'ERA Rosters'}
              </Typography>
              {!registration && <Login />}
              {!registration && <HeaderMenu />}
            </Toolbar>
          </AppBar>
        </Box>

        {/* <Box displayPrint="none">
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{secondaryListItems}</List>
        </Drawer>
      </Box> */}

        <main className={classes.content}>
          <div className={`${classes.appBarSpacer} noprint`} />
          {registration && <Route path="/" exact component={RegistrationForm} />}
          <Route
            path="/"
            exact
            render={() => (
              <Box className={classes.container}>
                {(user && <RosterList />) || <div />}
                {/* <Paper className={classes.paper}>{(user && <Roster />) || <div />}</Paper> */}
              </Box>
            )}
          />

          <Route path="/admin" exact component={AdminPage} />
          <Route path="/qbo" exact component={AccountingPage} />
          <Route path="/register" exact component={RegistrationForm} />
          <Route path="/juniors" exact component={RegistrationForm} />
          <Route path="/masters" exact component={RegistrationForm} />
          <Route path="/about" exact component={About} />
        </main>
      </div>
    </Router>
  );
}
