import React, { useEffect, useState, useRef } from 'react';
import * as Keys from '../shared/Keys';
import { useForm } from '../hooks/UseForm';
import { Button, Grid, Typography } from '@mui/material';
import { EnumDef } from '../shared/Types';
import { RadioX, StringX } from '../util/FormX';
import makeStyles from '@mui/styles/makeStyles';
import firebase from 'firebase/compat/app';
import {
  useRegistrationAuthStep,
  AuthStep,
  useRegistrationMemberInfo,
  useRegistrationActiveStep,
} from './RegistrationState';
// Add the Firebase services that you want to use
import 'firebase/compat/auth';
import { useDataDocument } from '../hooks/UseDataList';
import fetch from 'node-fetch';
import { firebaseConfig } from '../fire';
import { useAuth } from '../hooks/UseAuth';
import { useRosterType } from '../data/RosterTypes';
import { Progress } from './Progress';
import { useIsAdmin } from '../hooks/UseUserState';
import { useMemberInfo } from '../hooks/UseMemberInfo';

declare var grecaptcha: any;
declare var window: any;

export const useStyles = makeStyles((theme) => ({
  codePrompt: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

interface MemberInfo {
  id?: string;
  phones?: string[];
  error?: string;
}

const QueryMemberDetail = () => {
  const [memberInfo] = useRegistrationMemberInfo();
  const [, setRegistrationActiveStep] = useRegistrationActiveStep();
  const [, setAuthStep] = useRegistrationAuthStep();
  const { setDefaults } = useForm();
  const [rosterType] = useRosterType();
  const [doc, loading, error] = useDataDocument(`${rosterType}/members/list/${memberInfo.id}`);
  const [, setMemberInfo] = useMemberInfo();
  const [showError, setShowError] = useState('');
  useEffect(() => {
    if (loading === true) {
      return;
    }
    if (error) {
      setShowError(error);
      setMemberInfo({});
    } else {
      setDefaults(doc);
      setMemberInfo(doc);
      setAuthStep(AuthStep.Idle);
      setRegistrationActiveStep((stepNum) => ++stepNum);
    }
  }, [loading, error, doc, setAuthStep, setMemberInfo, setDefaults, setRegistrationActiveStep]);

  if (showError) {
    return <Typography color="error">{`Error: ${showError}`}</Typography>;
  }
  return <Progress />;
};

export const VerifyPhone = (props: { onNext: () => void }) => {
  const classes = useStyles();
  const { values, setValue } = useForm();
  const [authStep, setAuthStep] = useRegistrationAuthStep();
  const [, setRegistrationActiveStep] = useRegistrationActiveStep();
  const [confirmationResult, setConfirmationResult] = useState<any>();
  const [memberInfo, setMemberInfo] = useRegistrationMemberInfo();
  const [user, initializing] = useAuth();
  const [verifyError, setVerifyError] = useState('');
  const [verifying, setVerifying] = useState(false);
  const [sendError, setSendError] = useState('');
  const [rosterType] = useRosterType();
  const [isAdmin] = useIsAdmin();

  let verifyNumber = values['phVerify'] || (memberInfo.phones || [])[0] || '';
  verifyNumber = verifyNumber.replace(/[^0-9]/g, '').replace(/^1/, '');
  const returningAthlete = Boolean(values[Keys.ReturningAthlete]);
  // recaptcha cleanup
  useEffect(() => {
    return () => {
      if (window.recaptchaWidgetId) {
        grecaptcha.reset(window.recaptchaWidgetId);
      }
      window.recaptchaVerifier = undefined;
      window.recaptchaWidgetId = undefined;
    };
  }, []);

  // Insure we start in Idle state
  useEffect(() => {
    setAuthStep(AuthStep.Idle);
  }, [setAuthStep]);

  // After we have checked for user auth state, query for existing member
  useEffect(() => {
    const { First, Last, Birthdate } = values;
    const doQuery = async () => {
      try {
        const response = await fetch(
          `https://${firebaseConfig.authDomain}/mcheck?Roster=${rosterType}&First=${First}&Last=${Last}&Birthdate=${Birthdate}`
        );
        if (!response.ok) {
          setSendError(`Error: Unable to query server.  Try again later.`);
          return;
        }
        const member = (await response.json()) as MemberInfo;

        //const member = JSON.parse(body) as MemberInfo;
        if (member.phones) {
          // Member found
          setMemberInfo(member);
          if (isAdmin) {
            setAuthStep(AuthStep.QueryMemberDetails);
          } else if (user && user.phoneNumber) {
            /** If we're already authed with a number, check to see if it's in the list */
            const last10 = user.phoneNumber.substring(user.phoneNumber.length - 10);
            const match = member.phones.find((phone) => phone.replace(/[^0-9]/g, '').endsWith(last10));
            if (match) setAuthStep(AuthStep.QueryMemberDetails);
            else setAuthStep((step) => ++step);
          } else {
            setAuthStep((step) => ++step);
          }
        } else {
          if (!returningAthlete) {
            if (isAdmin) {
              // Allow admin to proceed directly to end of auth
              setAuthStep(AuthStep.QueryMemberDetails);
            } else {
              setRegistrationActiveStep((stepNum) => ++stepNum);
            }
          } else {
            setSendError(`Unable to find athlete.  Check Name and Birthdate.`);
          }
        }
      } catch (e) {
        const msg = e instanceof Error ? e.message : String(e);

        console.log(`Query failed.  ${msg}`);
        setSendError(`Error: ${msg}. Try again later.`);
      }
    };
    if (authStep === AuthStep.Idle && !initializing) {
      setAuthStep(AuthStep.QueryMemberExists);

      doQuery();
    }
  }, [
    initializing,
    rosterType,
    returningAthlete,
    authStep,
    setAuthStep,
    setRegistrationActiveStep,
    setMemberInfo,
    values,
    user,
    isAdmin,
  ]);

  // Initiate Recaptcha when entering UserInput state
  useEffect(() => {
    if (authStep === AuthStep.UserInput) {
      if (!window.recaptchaVerifier) {
        const verifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
          size: 'invisible',
          callback: (response: any) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        });
        window.recaptchaVerifier = verifier;
        verifier.render().then(function (widgetId: any) {
          window.recaptchaWidgetId = widgetId;
        });
      }
    }
  }, [authStep, setValue]);

  const onSendCodeClicked = () => {
    var appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(`+1${verifyNumber.replace('+1', '')}`, appVerifier)
      .then(function (confirmationResult) {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setAuthStep(AuthStep.WaitingForResopnse);
        setConfirmationResult(confirmationResult);
      })
      .catch(function (error) {
        setSendError(`Error sending SMS code: ${error.message}`);
      });
  };

  let sendCodeClickedRef = useRef(onSendCodeClicked); // Allow current value in useEffect
  sendCodeClickedRef.current = onSendCodeClicked;

  const onVerifyCodeClicked = () => {
    console.log('verifying with ' + values.verifyCode);
    setVerifying(true);
    confirmationResult
      .confirm(values.verifyCode)
      .then(function (result: any) {
        // User signed in successfully.
        setAuthStep(AuthStep.QueryMemberDetails);
        var user = result.user;
        console.log('signed in! ' + (user.email || user.phoneNumber));
        // ...
      })
      .catch(function (error: any) {
        console.log(error);
        setVerifyError('Invalid verification code.  Retry or press Back to resend');
        setVerifying(false);
        // User couldn't sign in (bad verification code?)
        // ...
      });
  };

  const renderStep = () => {
    switch (authStep) {
      case AuthStep.Idle:
      case AuthStep.QueryMemberExists:
        return sendError ? <Typography color="error">{sendError}</Typography> : <Progress />;
      case AuthStep.UserInput: {
        if (isAdmin) {
          setAuthStep(AuthStep.QueryMemberDetails);
          return <></>;
        }
        const enums = (memberInfo.phones || []).map((phone) => {
          const last4 = phone.substring(phone.length - 4);
          return { title: `xxx-xxx-${last4}`, key: phone } as EnumDef;
        });
        return (
          <Grid item container xs={12} className={classes.codePrompt}>
            <Grid item xs={6}>
              <RadioX
                name="phVerify"
                label="Verify Account with SMS message"
                defaultValue={(enums[0] && enums[0].key) || ''}
                mapping={enums}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography>
                Please verify access to this account by receiving a SMS verification code on the selected account
                number. Message and data rates may apply.
              </Typography>
            </Grid>
            <Grid container direction="column">
              {sendError && <Typography color="error">{sendError}</Typography>}
              <div>
                <Button
                  id="send-button"
                  variant="contained"
                  onClick={sendCodeClickedRef.current}
                  color="primary"
                  className={classes.button}
                >
                  Send Code
                </Button>
                <Button
                  id="send-button"
                  variant="contained"
                  onClick={() => setRegistrationActiveStep((stepNum) => ++stepNum)}
                  color="primary"
                  className={classes.button}
                >
                  Skip Validation
                </Button>
              </div>
            </Grid>
          </Grid>
        );
      }
      case AuthStep.WaitingForResopnse: {
        return (
          <Grid item container xs={12} className={classes.codePrompt}>
            <Grid item xs={6}>
              <StringX name="verifyCode" label="SMS Code" />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {`A SMS code has been sent to xxx-xxxx-${verifyNumber.substring(
                  verifyNumber.length - 4
                )}. Please enter the code here and
              click VERIFY.`}
              </Typography>
            </Grid>
            <Grid container direction="column">
              {verifyError && <Typography color="error">{verifyError}</Typography>}
              <div>
                {verifying ? (
                  <Progress />
                ) : (
                  <Button variant="contained" color="primary" onClick={onVerifyCodeClicked}>
                    Verify
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        );
      }

      case AuthStep.QueryMemberDetails:
        return <QueryMemberDetail />;
      default:
        return <div>{`Hello Verify Phone ${JSON.stringify({ authStep, values })}`}</div>;
    }
  };
  return (
    <div>
      {renderStep()}
      <div id="recaptcha" />
    </div>
  );
};
