import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FormData } from '../hooks/UseForm';
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Divider,
  Box,
  Paper,
  Snackbar,
  SnackbarContent,
  IconButton,
} from '@mui/material';
import { SnackbarCloseReason } from '@mui/material/Snackbar';
import makeStyles from '@mui/styles/makeStyles';
import { DefaultFieldConfig } from '../data/FieldTypes';
import { ArgMap } from '../shared/Types';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import { RegistrationConfigs, useRosterType } from '../data/RosterTypes';
import { RegistrationPage } from './RegistrationPage';
import { useRegistrationActiveStep, useRegistrationAuthStep, AuthStep } from './RegistrationState';
import { curlyReplace, saferEval, validYMD, validMDY, convertMDY2YMD } from '../util/RosterUtils';
import { Section, PageSection } from './RegistrationTypes';
import { firebaseConfig } from '../fire';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    width: 600,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(1),
  },
  box: {},
  info: { margin: theme.spacing(1) },
  divider: { marginBottom: theme.spacing(1) },
  error: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const RegistrationForm = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useRegistrationActiveStep();
  const [, setAuthStep] = useRegistrationAuthStep();
  const [errors, setErrors] = useState({} as ArgMap<string>);
  const [defaults, setDefaults] = useState({} as ArgMap);
  const [openMessage, setMessageOpen] = React.useState(false);
  const [formValues, setFormValues] = useState({} as ArgMap);
  const [extraValues, setExtraValues] = useState({} as ArgMap<ArgMap>);
  const [rosterType] = useRosterType();

  const history = useHistory();

  const registrationConfig = useMemo(() => RegistrationConfigs[rosterType] || ([] as PageSection[]), [rosterType]);

  const doWarmupQuery = async () => {
    try {
      await fetch(`https://${firebaseConfig.authDomain}/mcheck?First=A&Last=A&Birthdate=1940`);
    } catch (e) {}
  };

  useEffect(() => {
    setActiveStep(0);
    setAuthStep(AuthStep.Idle);
    doWarmupQuery();
    setFormValues({});
  }, [setAuthStep, setActiveStep, setFormValues]);

  const handleBack = useCallback(() => {
    // Go back a page, skipping any with a disabled Next button
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 0) return prevActiveStep;
      let nextStep = prevActiveStep - 1;
      while (registrationConfig[nextStep][0].disableNext) {
        nextStep = nextStep - 1;
      }
      return nextStep;
    });
  }, [registrationConfig, setActiveStep]);

  useEffect(() => {
    // Listen for history change and go back one in stepper
    return history.listen((newLocation, action) => {
      if (action === 'POP') {
        // Unfortunately both forward and back are a 'POP'.  going back always
        // works OK but not ideal.
        // https://github.com/ReactTraining/history/issues/334
        handleBack();
      }
    });
  }, [history, handleBack]);

  const sectionVisible = (section: Section) => {
    if (!section.showIf) return true;
    const expr = curlyReplace(section.showIf, formValues);
    const show = saferEval(expr);
    return show;
  };

  function handleClose(event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return;
    }
    setMessageOpen(false);
  }

  function handleCloseClick() {
    setMessageOpen(false);
  }

  // console.log('reg form extra=' + JSON.stringify(extraValues));
  const handleNext = () => {
    setActiveStep((currentStep) => {
      const page = registrationConfig[currentStep];

      // validate page before moving on
      const pageErrors = {} as ArgMap<string>;
      let sectionProps = {} as ArgMap<string>;
      page.forEach((section, index) => {
        const fieldConfig = section.fieldConfig
          ? { ...DefaultFieldConfig, ...section.fieldConfig }
          : DefaultFieldConfig;
        const visible = sectionVisible(section);
        if (!visible) {
          return;
        }

        if (section.props) {
          sectionProps = { ...sectionProps, ...section.props };
        }
        section.keys &&
          section.keys.forEach((key) => {
            let err = !formValues[key] && !defaults[key] && (!section.optionals || !section.optionals.includes(key));
            if (err) {
              pageErrors[key] = 'Required';
            } else {
              // Extra error checking per field
              const field = fieldConfig[key];
              if (field) {
                switch (field.type) {
                  case 'date':
                    let v = formValues[key] || '';
                    if (validMDY(v)) {
                      v = convertMDY2YMD(v);
                    }
                    err = !validYMD(v);
                    if (err) {
                      pageErrors[key] = 'Invalid Date';
                      break;
                    }
                    if (field.minDate) {
                      let minDate = new Date();
                      if (field.minDate.match(/^[-+].*/)) {
                        minDate.setDate(minDate.getDate() + Number(field.minDate));
                      } else {
                        minDate = new Date(field.minDate);
                      }
                      const d = new Date(v);
                      if (d < minDate) {
                        pageErrors[key] = 'Date < min allowed';
                        break;
                      }
                    }
                    if (field.maxDate) {
                      let maxDate = new Date();
                      if (field.maxDate.match(/^[-+].*/)) {
                        maxDate.setDate(maxDate.getDate() + Number(field.maxDate));
                      } else {
                        maxDate = new Date(field.maxDate);
                      }
                      const d = new Date(v);
                      if (d > maxDate) {
                        pageErrors[key] = 'Date > max allowed';
                        break;
                      }
                    }
                    break;
                }
              }
            }
          });
      });

      setErrors(pageErrors);

      if (Object.keys(pageErrors).length !== 0) {
        setMessageOpen(true);
        return currentStep; // cannot proceed
      }

      // Update defaults if moving past the Athlete page
      if (page[0].title === 'Athlete Name') {
        // if (!values.verified) {
        //   setDefaults(current => ({ ...current, needVerify: true }));
        //   return currentStep;
        // }
        // // If athlete in the system, use the current values
        // if (values[Keys.First] === 'Jane') {
        //   setDefaults({ ...Jane });
        // } else {
        //   setDefaults({});
        // }
      }
      setExtraValues((prior) => ({ ...prior, [String(activeStep)]: sectionProps }));
      const nextStep = currentStep + 1;
      if (nextStep < registrationConfig.length) {
        return nextStep;
      } else {
        return currentStep;
      }
    });
  };

  const renderPage = (pageNum: number) => {
    const page = registrationConfig[pageNum];
    return page.map((section) => {
      if (!sectionVisible(section)) {
        return null;
      }
      const title = section.label || section.title;
      return (
        <React.Fragment key={title}>
          <Grid container spacing={0}>
            {title && (
              <Grid item xs={12} className={classes.header}>
                <Typography align="left">{title}</Typography>
              </Grid>
            )}
            {title && (
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
            )}
          </Grid>
          <RegistrationPage
            onNext={handleNext}
            section={section}
            fieldConfig={section.fieldConfig ? { ...DefaultFieldConfig, ...section.fieldConfig } : DefaultFieldConfig}
          />
        </React.Fragment>
      );
    });
  };

  const page = registrationConfig[activeStep];
  return (
    <FormData
      defaultValues={defaults}
      errors={errors}
      onSubmit={(values) => {
        console.log(`submitting ${JSON.stringify(values)})`);
      }}
      onChange={(key: string, value: string) => {
        // If we previously had an error, clear it
        setErrors((prior) => (prior[key] ? { ...prior, [key]: '' } : prior));
        return true;
      }}
      setDefaults={setDefaults}
      formValues={formValues}
      extraValues={extraValues}
      setFormValues={setFormValues}
    >
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openMessage}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <SnackbarContent
            className={classes.error}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
                "Please enter required items"
              </span>
            }
            action={[
              <IconButton key="close" aria-label="close" color="inherit" onClick={handleCloseClick} size="large">
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
        </Snackbar>
        <Stepper activeStep={activeStep}>
          {registrationConfig.map((page, index) => {
            const stepProps = {};
            const labelProps = {};
            const label = page[0].title;
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box display="flex" justifyContent="center" className={classes.box}>
          <Paper className={classes.root}>
            {renderPage(activeStep)}
            <Grid container spacing={0} className={classes.buttons}>
              <Grid container item xs={6} justifyContent="flex-end">
                {!page[0].hidePrev && activeStep > 0 && (
                  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                    Back
                  </Button>
                )}
              </Grid>
              <Grid item xs={6}>
                {!page[0].hideNext && (
                  <Button
                    disabled={Boolean(page[0].disableNext)}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {page[0].nextLabel || 'Next'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </React.Fragment>
    </FormData>
  );
};
export default RegistrationForm;
