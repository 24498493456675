import React from 'react';
import firebase from 'firebase/compat/app';

// Add the Firebase services that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import './fire';
// firebase
//   .firestore()
//   .enablePersistence({ synchronizeTabs: true })
//   .catch(function(err) {
//     if (err.code === 'failed-precondition') {
//       console.log('Multiple tabs open, persistence can only be enabled in one tab at a a time.');
//     } else if (err.code === 'unimplemented') {
//       console.log(
//         'The current browser does not support all of the features required to enable persistence'
//       );
//     }
//   });

const provider = new firebase.auth.GoogleAuthProvider();

const Login = () => {
  const [user, initializing, error] = useAuthState(firebase.auth());
  const login = () => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        console.log('User logged in ' + (user && user.email));
        // ...
      })
      .catch(function (error) {
        console.log('Auth error: ' + JSON.stringify(error));
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // The email of the user's account used.
        // var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
      });
  };
  // const logout = () => {
  //   firebase.auth().signOut();
  // };

  if (initializing) {
    return (
      <div>
        <p>Initializing User...</p>
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }
  if (user) {
    return (
      <div>
        <p>Current User: {user.email || user.phoneNumber}</p>
      </div>
    );
  }
  return <button onClick={login}>Log in</button>;
};
export default Login;
