import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef
} from 'react';

type SetPropertyFunc = (name: string, value: any | undefined) => void;
const globalProperties: { [key: string]: any } = {};
let contextPropertySetter: SetPropertyFunc = () => {};

export async function setProperty(name: string, value: any) {
  contextPropertySetter(name, value);
  return Promise.resolve();
}

interface GlobalPropertyContextType {
  properties: {
    [key: string]: any;
  };
  setProperty: SetPropertyFunc;
}

const GlobalPropertyContextDefaults: GlobalPropertyContextType = {
  properties: globalProperties,
  setProperty: () => {
    /* nothing */
  }
};

const GlobalPropertyContext = createContext(GlobalPropertyContextDefaults);

interface Props {
  children?: ReactNode;
}

const _GlobalProperty = (props: Props) => {
  const { children } = props; //
  const [properties, setProperties] = useState(globalProperties);

  const setProperty = useMemo(
    () => (name: string, value: any | undefined) => {
      setProperties(prev => {
        return { ...prev, [name]: value };
      });
    },
    [setProperties]
  );

  useEffect(() => {
    contextPropertySetter = setProperty;
    return () => {
      contextPropertySetter = () => {};
    };
  }, [setProperty]);

  return (
    <GlobalPropertyContext.Provider value={{ properties, setProperty }}>
      {children}
    </GlobalPropertyContext.Provider>
  );
};
export const GlobalProperty = _GlobalProperty;

/**
 * Use a global property.  If the property has not been set it's
 * value will be the defaultValue specified.   Usage:
 *
 * `const [value, setValue] = useGlobalPropertyWithDefault('FirstName','Joe');`
 *
 * @param name The name of the global property
 * @param defaultValue The value to return if the property is not set or read from storage is pending.
 * @param persistent Optional - True if the value should be read and stored in persistent storage.
 */
export function useGlobalPropertyWithDefault<T>(
  name: string,
  defaultValue: T,
  persistent: boolean = false
): [T, (value: T | ((curValue: T) => T)) => void] {
  const ctx = useContext(GlobalPropertyContext);
  const ctxRef = useRef(ctx);
  ctxRef.current = ctx;
  let value = ctx.properties[name] as T;

  if (value === undefined) {
    value = defaultValue;
  }

  const setValue = useMemo(() => {
    return (value: T | ((curValue: T) => T)) => {
      if (value instanceof Function) {
        ctxRef.current.setProperty(name, value(ctxRef.current.properties[name] as T));
      } else {
        ctxRef.current.setProperty(name, value);
      }
    };
  }, [name]);
  return [value, setValue];
}

/**
 * Use a global property.  If the property has not been set it's
 * value will be undefined. See useGlobalPropertyWithDefault for a
 * version whose value is never undefined.  Usage:
 *
 * `const [value, setValue] = useGlobalProperty<string>('FirstName');`
 *
 * @param name The name of the global property
 * @param persistent Optional - True if the value should be read and stored in persistent storage.
 */
export function useGlobalProperty<T>(
  name: string,
  persistent: boolean = false
): [T | undefined, (value: T | undefined) => void] {
  return useGlobalPropertyWithDefault<T | undefined>(name, undefined, persistent);
}
