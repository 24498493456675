/* eslint-disable no-script-url */

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { Grid, Select, Input, MenuItem } from '@mui/material';
import { ArgMap, FilterField } from './shared/Types';
import { useIsAdmin } from './hooks/UseUserState';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  print: {
    marginLeft: theme.spacing(3),
  },
  search: {
    marginLeft: theme.spacing(3),
  },
}));

const FilterOption = (props: {
  name: string;
  optionList: string[];
  selectedOptions: { [key: string]: boolean };
  handleChange: (event: any) => void;
}) => {
  const classes = useStyles();
  const { name, optionList, selectedOptions, handleChange } = props;
  const numCols = optionList.length > 4 ? 2 : 1;

  // Generate a straight list for 1 column.  Otherwise a multi-column table.
  const GenList = () => {
    if (numCols === 1) {
      return (
        <>
          {optionList.map((option, index) => (
            <FormControlLabel
              key={index}
              control={<Checkbox checked={selectedOptions[option] || false} onChange={handleChange} value={option} />}
              label={option}
            />
          ))}
        </>
      );
    } else {
      const rows = [];
      let cols = [];
      for (let index = 0; index < optionList.length; index++) {
        const option = optionList[index];
        cols.push(
          <td key={String(cols.length)}>
            <FormControlLabel
              key={index}
              control={<Checkbox checked={selectedOptions[option] || false} onChange={handleChange} value={option} />}
              label={option}
            />
          </td>
        );
        if (cols.length < numCols && index === optionList.length - 1) {
          while (cols.length < numCols) cols.push(<td key={String(index + cols.length + 1)} />);
        }
        if (cols.length === numCols) {
          rows.push(<tr key={String(rows.length)}>{cols}</tr>);
          cols = [];
        }
      }
      return (
        <table>
          <tbody>{rows}</tbody>
        </table>
      );
    }
  };
  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{name}</FormLabel>
      <FormGroup>
        <GenList />
      </FormGroup>
      <FormHelperText>Select {name}</FormHelperText>
    </FormControl>
  );
};

export interface DisplayFilters {
  [key: string]: ArgMap<boolean>;
}

export interface FilterConfig {
  filters: DisplayFilters;
}
interface Props {
  choices: { [key: string]: string[] };
  values: DisplayFilters;
  filterFields: FilterField[];
  onChange: (values: DisplayFilters) => void;
}
export default function DisplayFilter(props: Props) {
  const classes = useStyles();
  const [isAdmin] = useIsAdmin();
  const { choices, values, onChange, filterFields } = props;

  const handleSetChange = (key: string) => (event: any) => {
    // const name = event.target.name;
    const value = event.target.value;
    const checked = event.target.checked;
    const filterField = filterFields.find((field) => field.settingKey === key);
    if (filterField && filterField.uiType === 'checkboxmulti') {
      // allow more than one
      if (checked) {
        values[key][value] = checked;
      } else {
        delete values[key][value];
      }
    } else {
      values[key] = { [value]: checked };
    }
    onChange(values);
  };

  function handleChange(event: any) {
    const name = event.target.name as 'listType';
    const value = event.target.value;
    values[name] = { [value]: true };
    onChange(values);
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {filterFields.map((filter) => {
          let optionList: string[] = isAdmin ? filter.adminOptionList || filter.optionList : filter.optionList;
          optionList = [...optionList];
          let choiceOptions = choices[filter.key];
          if (filter.autoList && choiceOptions) {
            choiceOptions = choiceOptions.filter((opt) => !optionList.includes(opt));
            optionList = [...optionList, ...choiceOptions];
          }
          return (
            <Grid item xs key={filter.title}>
              {filter.uiType === 'checkbox' || filter.uiType === 'checkboxmulti' ? (
                <FilterOption
                  name={filter.title}
                  optionList={optionList}
                  selectedOptions={values[filter.settingKey]}
                  handleChange={handleSetChange(filter.settingKey)}
                />
              ) : (
                <FormControl className={classes.formControl}>
                  <FormLabel component="legend">{filter.title}</FormLabel>
                  <Select
                    // Only one is set at a time to use first item in set
                    value={Object.keys(values[filter.settingKey])[0] || ''}
                    name={filter.settingKey}
                    onChange={handleChange}
                    input={<Input name={filter.settingKey} id="list-type" />}
                    autoWidth
                  >
                    {optionList.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Choose {filter.title}</FormHelperText>
                </FormControl>
              )}
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
}
