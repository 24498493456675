import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Title from '../Title';
import makeStyles from '@mui/styles/makeStyles';
import { useGlobalPropertyWithDefault } from '../hooks/UseGlobalProperty';
import { Button, Link } from '@mui/material';
import { CurrencyFormatter } from '../data/FieldTypes';
import { useMemberInfo, usePaymentAmount } from '../hooks/UseMemberInfo';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(3),
  },
  text: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
}));
export const About = () => {
  const classes = useStyles();
  const [paymentLink] = useGlobalPropertyWithDefault('paymentLink', '');
  const [paymentAmount] = usePaymentAmount();
  const [paymentClicked, setPaymentClicked] = useState('');
  const [memberInfo] = useMemberInfo();
  const [link, setLink] = useState('');
  const balance = Number(memberInfo.Balance || 0);
  const balanceDate = new Date(memberInfo.BalanceDate).toLocaleDateString('en-CA');
  let accountPayment = paymentAmount;
  if (balance < 0) {
    accountPayment = paymentAmount + balance;
    if (accountPayment < 0) {
      accountPayment = 0;
    }
  } else if (balance > 0) {
    accountPayment = accountPayment + balance;
  }
  const sAccountPayment = CurrencyFormatter.format(accountPayment);
  const onSubmit = () => {
    const link = `${paymentLink}&field_26=${paymentAmount}`;
    setLink(link);
    setPaymentClicked('normal');
    window.location.href = link;
  };

  const onCustom = () => {
    setLink(paymentLink);
    setPaymentClicked('custom');
    window.location.href = paymentLink;
  };

  const payUsingAccount = () => {
    const link = `${paymentLink}&field_26=${accountPayment}`;
    setLink(link);
    if (accountPayment > 0) {
      setPaymentClicked('account');
      window.location.href = link;
    } else {
      setPaymentClicked('zero');
    }
  };
  return (
    <div className={classes.container}>
      <Title>Registration Accepted!</Title>
      {balance < 0 && (
        <Typography color="red">
          Note: Your account has a CREDIT of {CurrencyFormatter.format(-balance)} as of {balanceDate}.
        </Typography>
      )}
      {balance > 0 && (
        <Typography color="red">
          Note: Your account has a BALANCE DUE of {CurrencyFormatter.format(balance)} as of {balanceDate}.
        </Typography>
      )}
      {balance !== 0 && (
        <Typography>
          Account payments or additional services may not be reflected in this balance for several days.
        </Typography>
      )}

      {paymentClicked ? (
        <>
          <Typography>Thank you!</Typography>
          {paymentClicked !== 'zero' && (
            <div>
              <Typography className={classes.text}>
                If the payment page does not load, please click on the link below:
              </Typography>

              <Link color="primary" href={link} className={classes.text}>
                Payment Link
              </Link>
            </div>
          )}
        </>
      ) : (
        <>
          {balance < 0 && (
            <>
              <Typography className={classes.text}>Please click on the link below to complete your payment.</Typography>
              <Button variant="contained" color="primary" onClick={payUsingAccount} className={classes.button}>
                Use my Credit, Pay {sAccountPayment}
              </Button>
            </>
          )}
          {balance > 0 && (
            <Button variant="contained" color="primary" onClick={payUsingAccount} className={classes.button}>
              Pay Full Balance of {sAccountPayment})
            </Button>
          )}
          <Button variant="contained" color="primary" onClick={onSubmit} className={classes.button}>
            Pay {CurrencyFormatter.format(paymentAmount)}
          </Button>
          <Button variant="contained" color="primary" onClick={onCustom} className={classes.button}>
            Custom Amount
          </Button>
        </>
      )}
    </div>
  );
};
export default About;
