import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import makeStyles from '@mui/styles/makeStyles';
import useUserState, { useIsAdmin } from './hooks/UseUserState';
import { useRosterType } from './data/RosterTypes';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { csvExport } from './util/CSVExport';
const useStyles = makeStyles((theme) => ({
  menuButton: {
    width: 40,
    alignSelf: 'flex-end',
  },
}));

const HeaderMenu = (props: {}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [user] = useUserState();
  const [isAdmin, superAdmin] = useIsAdmin();
  const [rosterType, setRosterType] = useRosterType();
  const history = useHistory();
  if (!user) {
    return null;
  }

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    handleClose();
    firebase.auth().signOut();
  };

  const registerAthlete = () => {
    history.push(`/${rosterType}`);
  };
  return (
    <React.Fragment>
      <IconButton
        className={`${classes.menuButton} noprint`}
        color="inherit"
        aria-label="Menu"
        onClick={handleOpen}
        size="large"
      >
        <MenuIcon />
      </IconButton>
      <Menu id="render-props-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {superAdmin && (
          <MenuItem
            onClick={() => {
              csvExport();
            }}
          >
            CSV Export
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            setRosterType('masters');
            history.replace('/');
          }}
        >
          Masters Roster
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setRosterType('juniors');
            history.replace('/');
          }}
        >
          Junior Roster
        </MenuItem>
        {isAdmin && (
          <MenuItem
            onClick={() => {
              handleClose();
              registerAthlete();
            }}
          >
            Register Athlete
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem
            onClick={() => {
              handleClose();
              history.replace('/qbo');
            }}
          >
            Quickbooks Update
          </MenuItem>
        )}
        <MenuItem onClick={logout}>Log out</MenuItem>
      </Menu>
    </React.Fragment>
  );
};
export default HeaderMenu;
