import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useGlobalPropertyWithDefault } from './hooks/UseGlobalProperty';
import { FormData } from './hooks/UseForm';
import { ArgMap, ListConfig, FieldConfig } from './shared/Types';
import * as Keys from './shared/Keys';
import makeStyles from '@mui/styles/makeStyles';
import { useDataList, useDataMap } from './hooks/UseDataList';
import useAlert from './hooks/UseAlertDialog';
import { FormValues } from './util/FormX';
import { useRosterType, FieldConfigs } from './data/RosterTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  label: {
    paddingRight: theme.spacing(2),
  },
}));

const EditRecord = () => {
  const [editConfig, setEditConfig] = useGlobalPropertyWithDefault<{
    listConfig?: ListConfig;
    open: boolean;
    value?: ArgMap;
    auxFieldConfig?: FieldConfig;
  }>('EditRecord', {
    open: false,
  });
  const [rosterType] = useRosterType();
  const { update: updateMember } = useDataMap<ArgMap>(`${rosterType}/members`);
  const { update: updateRegistration } = useDataList<ArgMap>(`${rosterType}/registrations`);
  const [showAll, setShowAll] = useState(false);
  const [formValues, setFormValues] = useState({} as ArgMap);
  const setAlert = useAlert();
  const classes = useStyles();
  const record = editConfig.value || {};
  const listConfig = editConfig.listConfig;
  const fieldConfig = { ...FieldConfigs[rosterType], ...(editConfig.auxFieldConfig || {}) };

  if (!record[Keys.Last] || !listConfig) {
    return null;
  }
  const editcols = (showAll ? listConfig.editallcols : listConfig.editcols) || listConfig.editcols || [];

  const handleClose = () => {
    let doMember = false;
    let doRegistration = false;
    // const member = members[record[Keys.MemberId]];
    const memberUpdate: ArgMap = {
      [Keys.Id]: record[Keys.MemberId],
      [Keys.ChangeType]: Keys.ChangeEdit,
    };
    const registrationUpdate: ArgMap = {
      [Keys.Id]: record[Keys.Id],
      [Keys.MemberId]: record[Keys.MemberId],
      [Keys.ChangeType]: Keys.ChangeEdit,
    };
    editcols.forEach((key) => {
      const config = fieldConfig[key];
      if (!config || !formValues[key] || record[key] === formValues[key]) {
        return;
      }
      switch (config.src) {
        case Keys.RegList:
          doRegistration = true;
          registrationUpdate[key] = formValues[key];
          break;
        case Keys.MemberList:
          doMember = true;
          memberUpdate[key] = formValues[key];
          break;
        default:
          break;
      }
    });
    if (doRegistration) {
      updateRegistration(registrationUpdate, true).catch((e) => setAlert({ title: 'Save Failed', message: String(e) }));
    }
    if (doMember) {
      memberUpdate[Keys.ChangeType] = Keys.ChangeEdit;
      updateMember(memberUpdate, true).catch((e) => setAlert({ title: 'Save Failed', message: String(e) }));
    }

    setEditConfig({ open: false });
    setFormValues({});
  };
  const handleCancel = () => {
    setEditConfig({ open: false });
    setFormValues({});
  };

  const handleShowAll = (event: any) => {
    const checked = event.target.checked;
    setShowAll(checked);
  };

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={Boolean(editConfig.open)}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{`${record[Keys.Last]}, ${record[Keys.First]}`}</DialogTitle>
      <DialogContent>
        <FormData
          formValues={formValues}
          setFormValues={setFormValues}
          defaultValues={record}
          errors={{}}
          onSubmit={(values) => {
            console.log(`submitting ${JSON.stringify(values)})`);
          }}
          onChange={(key: string, value: string) => {
            return true;
          }}
        >
          <Grid container spacing={0} alignItems="center">
            {listConfig.editallcols && (
              <React.Fragment>
                <Grid item xs={6} className={classes.label} />
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox checked={showAll} onChange={handleShowAll} value={showAll} />}
                    labelPlacement="start"
                    label={'Show All Fields'}
                  />
                </Grid>
              </React.Fragment>
            )}
            <FormValues keys={editcols} fieldConfig={fieldConfig} />
          </Grid>
        </FormData>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClose} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRecord;
