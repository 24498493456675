import React from 'react';
import { useGlobalProperty, useGlobalPropertyWithDefault } from './UseGlobalProperty';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material';

interface Alert {
  title?: string;
  message: string;
  onRight?: () => void;
  onLeft?: () => void;

  onLeftText?: string;
  onRightText?: string;
}
export const AlertDialog = () => {
  const [alert, setAlert] = useGlobalPropertyWithDefault('alert', { message: '' } as Alert);
  const handleLeft = () => {
    setAlert({ message: '' });
    if (alert.onLeft) {
      alert.onLeft();
    }
  };
  const handleRight = () => {
    setAlert({ message: '' });
    if (alert.onRight) {
      alert.onRight();
    }
  };
  return (
    <Dialog open={Boolean(alert.message)} fullScreen={false}>
      {alert.title && <DialogTitle>{alert.title}</DialogTitle>}
      <DialogContent>
        <Typography>{alert.message}</Typography>
      </DialogContent>
      <DialogActions>
        {alert.onLeft && (
          <Button onClick={handleLeft} color="primary">
            {alert.onLeftText || 'Cancel'}
          </Button>
        )}
        <Button onClick={handleRight} color="primary">
          {alert.onRightText || 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const useAlert = () => {
  const [, setAlert] = useGlobalProperty('alert');
  return setAlert;
};
export default useAlert;
