import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as Keys from '../shared/Keys';
// import attendanceMembers from '../data/attendance.json';
import { ArgMap } from '../shared/Types';
import mid2lglraw from '../data/mid2lgl.json';
import FileSaver from 'file-saver';

const mid2lgl = mid2lglraw as { [key: string]: string };
interface Member {
  First: string;
  Last: string;
  Birthdate: string;
  firstName?: string;
  lastName?: string;
  Mid: string;
  id: string;
  Year: string;
  [Keys.Phone]: string;
  [Keys.Email]: string;
  [Keys.Program]: string;
  [Keys.TimeSlot]: string;
}

// var idCol = getColumn_('LGL Constituent ID');
// var firstNameCol = getColumn_('First Name');
// var lastNameCol = getColumn_('Last Name');
// var groupsCol = getColumn_('Groups');
// var emailCol = getColumn_('Pref. Email');
// var phoneCol = getColumn_('Pref. Phone');
// var memberStatusCol = getColumn_('Membership status');
// var memberLevelCol = getColumn_('Membership level');
// membershipLevels=['Annual', 'Quarterly', 'Drop-in', 'Collegiate Training', 'Novice', 'Boat Storage'];
// membershipStatus=['Active','Lapsed'];
// groups=['Masters','Night Crew','Mid Morning','Drop-in Rower','Alumni','Junior Alumni','Mid Morning','Morning Glories','Alumni Parent','Auction Donor','Late Bloomers'];

async function getList<T = any>(path: string) {
  const db = firebase.firestore();
  const ref = db.collection(path);
  const snapshot = await ref.get();
  // .catch((err: any) => {
  //   console.log('Error getting documents', err);
  //   return { empty: true };
  // });
  if (snapshot.empty) {
    console.log('No documents.');
    return [];
  }
  const list: T[] = [];
  snapshot.forEach((doc: any) => list.push(doc.data()));
  return list as T[];
}
export async function csvExport() {
  const rosterType = 'masters';
  let registrations: Member[] = await getList(`${rosterType}/registrations/list`);
  let members: Member[] = await getList(`${rosterType}/members/list`);

  // Only extract members registered in 2020 and beyone who we don't have
  // a mid2lgl mapping for.
  registrations = registrations.filter(
    (registration) => registration[Keys.ProgramYear].startsWith('202') && !mid2lgl[registration[Keys.MemberId]]
  );

  let exportMembers: Member[] = [];
  registrations.forEach((registration) => {
    const id = registration[Keys.MemberId];
    const member = members.find((m) => m[Keys.Id] === id);
    if (member) {
      member[Keys.ProgramYear] = registration[Keys.ProgramYear];
      member[Keys.TimeSlot] = registration[Keys.TimeSlot];
      member[Keys.Program] = registration[Keys.Program].replace(/ .*/, '');
      exportMembers.push(member);
    }
  });

  let csv: string[][] = [];
  csv.push([
    'LGL Constituent ID',
    'First Name',
    'Last Name',
    'Groups',
    'Pref. Email',
    'Pref. Phone',
    'Membership status',
    'Membership level',
  ]);

  const slotToGroup: ArgMap<string> = {
    Morning: 'Morning Glories',
    MidMorning: 'Mid Morning',
    LateMorning: 'Late Bloomers',
    Evening: 'Night Crew',
  };

  const currentYear = String(new Date().getFullYear());
  exportMembers.forEach((m) => {
    const lglid = mid2lgl[m[Keys.Id]] || m[Keys.Id];
    csv.push([
      lglid,
      m[Keys.First],
      m[Keys.Last],
      slotToGroup[m[Keys.TimeSlot]] || 'Masters',
      m[Keys.Email],
      m[Keys.Phone],
      currentYear === m[Keys.ProgramYear] ? 'Active' : 'Lapsed',
      m[Keys.Program],
    ]);
  });

  const csvText = csv.map((line) => line.map((item) => `"${String(item).replace(/"/g, '""')}"`).join(',')).join('\n');
  const blob = new Blob([csvText], { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(blob, `${rosterType}-lgl.csv`.replace(/ /g, ''));
}

// export async function mid2lglGeneration() {
//   const db = firebase.firestore();
//   const membersRef = db.collection(`masters/members/list`);
//   const snapshot = await membersRef.get();
//   // .catch((err: any) => {
//   //   console.log('Error getting documents', err);
//   //   return { empty: true };
//   // });
//   if (snapshot.empty) {
//     console.log('No documents.');
//     return [];
//   }
//   const members = [] as Member[];
//   snapshot.forEach((doc: any) => members.push(doc.data() as Member));
//   // const keys = members.map((m: any) => genKey(m));
//   const keys: string[] = [];
//   const lglKeys: string[] = Object.keys(attendanceMembers);
//   const byKey: { [key: string]: Member } = {};
//   lglKeys.forEach(key => {
//     const member = ((attendanceMembers as unknown) as { [key: string]: Member })[key];
//     keys.push(genKey1(member));
//     byKey[key] = member;
//   });

//   const mid2LGL: ArgMap<string> = {};
//   members.forEach(member => {
//     const key = genKey2(member);
//     const [slot, lev] = findNearest(keys, key);
//     if (slot < 0 || lev > 4) {
//       console.log('Not Found ' + key);
//     }
//     mid2LGL[member[Keys.MemberId]] = lglKeys[slot];
//   });
//   console.log(JSON.stringify(mid2lgl));
// }
