import { FieldConfig } from '../shared/Types';
import * as Keys from '../shared/Keys';

export const DefaultFieldConfig: FieldConfig = {
  '': { title: '', type: 'string', src: Keys.NoList },
  [Keys.MemberId]: { title: 'Mid', type: 'string', src: Keys.RegList },
  [Keys.Sid]: { title: 'Sid', type: 'string', src: Keys.RegList },
  [Keys.ReturningAthlete]: {
    title: 'Returning Athlete/Member',
    type: 'checkbox',
    src: Keys.NoList,
  },
  [Keys.First]: { title: 'First', type: 'string', src: Keys.MemberList },
  [Keys.Last]: { title: 'Last', type: 'string', src: Keys.MemberList },
  [Keys.Gender]: {
    title: 'Gender',
    type: 'enum',
    enums: [
      { key: 'Female', title: 'Female' },
      { key: 'Male', title: 'Male' },
    ],
    src: Keys.MemberList,
  },
  [Keys.Birthdate]: {
    title: 'Birthdate',
    type: 'date',
    minDate: '1920-01-01',
    maxDate: `-${11 * 365}`,
    src: Keys.MemberList,
  },
  [Keys.RowingAge]: {
    title: 'Rowing Age',
    type: 'string',
    src: Keys.MemberList,
  },
  [Keys.Balance]: {
    title: 'Balance',
    type: 'currency',
    src: Keys.MemberList,
  },
  [Keys.BalanceDate]: {
    title: 'QB Updated',
    type: 'date',
    src: Keys.MemberList,
  },
  [Keys.QBOFirst]: {
    title: 'QB First',
    type: 'string',
    src: Keys.MemberList,
  },
  [Keys.QBOLast]: {
    title: 'QB Last',
    type: 'string',
    src: Keys.MemberList,
  },
  [Keys.QboId]: {
    title: 'QB ID',
    type: 'string',
    src: Keys.MemberList,
    // eslint-disable-next-line no-template-curly-in-string
    link: 'https://app.qbo.intuit.com/app/customerdetail?nameId=${value}',
  },
  [Keys.School]: {
    title: 'School Name',
    type: 'enum',
    enumList: [
      'Archbishop Murphy High School',
      'Arlington High School',
      'Bothell High School',
      'Canyon Park',
      'Cascade High School',
      'Cavalero Mid-High',
      'Cedarcrest Junior High',
      'Centennial',
      'Edmonds CC',
      'Edmonds-Woodway High School',
      'Everett CC',
      'Everett High School',
      'Gateway Middle School',
      'Glacier Peak High School',
      'Harbour Pointe Middle School',
      'Homeschool',
      'Immaculate Conception Middle School',
      'Inglemoor',
      'Island Christian Academy',
      'Jackson High School',
      'Kamiak High School',
      'La Conner High School',
      'Lake Stevens High School',
      'Lakewood High School',
      'Lynnwood High School',
      'Marysville Pilchuck High School',
      'Marysville-Getchell High School',
      'Meadowdale High School',
      'Monroe High School',
      'Mountlake Terrace High School',
      'Northshore Networks',
      'ORCA / EVCC',
      'Port Susan Middle School',
      'Sedro Wooley',
      'Sequioa',
      'Snohomish High School',
      'South Whidbey High School',
      'St Mary Magdalen',
      'Stanwood High School',
      'Stillaguamish Valley Learning Center',
      'Other',
    ],
    src: Keys.RegList,
  },
  [Keys.OtherSchool]: {
    title: 'Other School Name',
    type: 'string',
    src: Keys.RegList,
  },
  [Keys.HSGrad]: {
    title: 'HS Year',
    label: 'HS Graduation Year',
    info: 'High School Graduation year',
    type: 'enum',
    enumList: [
      '2020',
      '2021',
      '2022',
      '2023',
      '2024',
      '2025',
      '2026',
      '2027',
      '2028',
      '2029',
      '2030',
      '2031',
      '2032',
      '2033',
      '2034',
      '2035',
      '2036',
      '2037',
      '2038',
      '2039',
    ],
    src: Keys.MemberList,
  },
  [Keys.Email]: { title: 'Email', type: 'email', src: Keys.MemberList },
  [Keys.Phone]: { title: 'Cell Phone', type: 'phone', src: Keys.MemberList },
  [Keys.Allergies]: { title: 'Allergies', type: 'string', src: Keys.MemberList },
  [Keys.MedCond]: { title: 'Medical Conditions', type: 'string', src: Keys.MemberList },
  [Keys.Meds]: { title: 'Medications', type: 'string', src: Keys.MemberList },

  [Keys.ParentName]: { title: 'Parent Name', type: 'string', src: Keys.MemberList },
  [Keys.ParentFirst]: {
    title: 'Parent First',
    label: 'First',
    type: 'string',
    src: Keys.MemberList,
  },
  [Keys.ParentLast]: {
    title: 'Parent Last',
    label: 'Last',
    type: 'string',
    src: Keys.MemberList,
  },
  [Keys.ParentEmail]: {
    title: 'Parent Email',
    label: 'Email',
    type: 'email',
    src: Keys.MemberList,
  },
  [Keys.ParentPhone]: {
    title: 'Parent Phone',
    label: 'Cell Phone',
    type: 'phone',
    src: Keys.MemberList,
  },

  [Keys.EmName]: { title: 'Emergency Contact', type: 'string', src: Keys.MemberList },
  [Keys.EmFirst]: {
    title: 'Em Contact First',
    label: 'First',
    type: 'string',
    src: Keys.MemberList,
  },
  [Keys.EmLast]: {
    title: 'Em Contact Last',
    label: 'Last',
    type: 'string',
    src: Keys.MemberList,
  },
  [Keys.EmEmail]: {
    title: 'Em Contact Email',
    label: 'Email',
    type: 'email',
    src: Keys.MemberList,
  },
  [Keys.EmPhone]: {
    title: 'Em Contact Phone',
    label: 'Phone',
    type: 'phone',
    src: Keys.MemberList,
  },
  [Keys.EmRelationship]: {
    title: 'Em Relationship',
    type: 'enum',
    label: 'Relationship to Athlete',
    enumList: ['Spouse', 'Parent', 'Child', 'Friend', 'Other'],
    src: Keys.MemberList,
  },

  [Keys.SecondaryName]: { title: 'Secondary Name', type: 'string', src: Keys.MemberList },
  [Keys.SecondaryFirst]: {
    title: 'Secondary First',
    label: 'First',
    type: 'string',
    src: Keys.MemberList,
  },
  [Keys.SecondaryLast]: {
    title: 'Secondary Last',
    label: 'Last',
    type: 'string',
    src: Keys.MemberList,
  },
  [Keys.SecondaryEmail]: {
    title: 'Secondary Email',
    label: 'Email',
    type: 'email',
    src: Keys.MemberList,
  },
  [Keys.SecondaryEmailList]: {
    title: 'Secondary Email List',
    label: 'Include in email announcements',
    type: 'checkbox',
    src: Keys.MemberList,
  },
  [Keys.SecondaryPhone]: {
    title: 'Secondary Phone',
    label: 'Phone',
    type: 'phone',
    src: Keys.MemberList,
  },
  [Keys.SecondaryRelation]: {
    title: 'Secondary Relation',
    type: 'enum',
    label: 'Relationship to Athlete',
    enumList: ['Parent', 'Guardian', 'Grandparent', 'Friend', 'Other'],
    src: Keys.MemberList,
  },
  [Keys.Address1]: { title: 'Address 1', type: 'string', src: Keys.MemberList },
  [Keys.Address2]: { title: 'Address 2', type: 'string', src: Keys.MemberList },
  [Keys.City]: { title: 'City', type: 'string', src: Keys.MemberList },
  [Keys.State]: { title: 'State', type: 'string', src: Keys.MemberList },
  [Keys.Zip]: { title: 'Zip', type: 'string', src: Keys.MemberList },

  [Keys.Program]: { title: 'Program', type: 'enum', src: Keys.RegList },
  [Keys.ProgramYear]: { title: 'Program Year', type: 'string', src: Keys.RegList },

  [Keys.Team]: {
    title: 'Team',
    type: 'enum',
    label: 'Rowing Squad',
    info: 'Varsity athletes have finished a spring season and competed at regionals.',
    enums: [
      { key: 'Novice', title: 'Novice' },
      { key: 'Varsity', title: 'Varsity' },
      { key: 'Middle School', title: 'Middle School' },
    ],
    src: Keys.RegList,
  },
  [Keys.Cox]: {
    title: 'Coxswain Role',
    type: 'enum',
    label: 'Cox Role',
    default: 'Rower',
    enums: [
      { key: 'Rower', title: 'Rower' },
      { key: 'MaleTeamCox', title: 'Mens Team Cox' },
      { key: 'FemaleTeamCox', title: 'Womens Team Cox' },
    ],
    src: Keys.RegList,
  },
  [Keys.Status]: {
    title: 'Status',
    type: 'enum',
    default: 'Active',
    enums: [
      { key: 'Active', title: 'Active' },
      { key: 'Withdrew', title: 'Withdrew' },
      { key: 'Deleted', title: 'Deleted' },
    ],
    src: Keys.RegList,
  },
  [Keys.PolicyAccept]: {
    title: 'Policy Acceptance',
    label: 'My athlete and I agree to the above policies',
    type: 'checkbox',
    src: Keys.RegList,
  },
  [Keys.MastersPolicyAccept]: {
    title: 'Policy Acceptance',
    label: 'I agree to the above policies',
    type: 'checkbox',
    src: Keys.RegList,
  },
  [Keys.SubmitDate]: {
    title: 'Submitted',
    info: 'Date submitted',
    type: 'date',
    src: Keys.RegList,
  },
  [Keys.CreateTimestamp]: {
    title: 'Submitted',
    info: 'Submitted',
    type: 'timestamp',
    src: Keys.RegList,
  },
  [Keys.Invoiced]: {
    title: 'Invoiced',
    info: 'Date invoiced',
    type: 'date',
    src: Keys.RegList,
  },
  [Keys.CovidWaiver]: {
    title: 'Covid Waiver',
    info: 'Date completed',
    type: 'date',
    allowFuture: false,
    src: Keys.MemberList,
  },
  [Keys.CovidVaccine]: {
    title: 'Covid Vaccine',
    type: 'checkbox',
    src: Keys.MemberList,
  },
  [Keys.Waiver]: {
    title: 'Waiver Expires',
    type: 'date',
    allowFuture: true,
    src: Keys.MemberList,
  },
  [Keys.USRMember]: {
    title: 'USR Expires',
    type: 'date',
    allowFuture: true,
    src: Keys.MemberList,
  },
  [Keys.AthleteSafesport]: {
    title: 'Athlete Safesport',
    info: 'Date completed',
    type: 'date',
    allowFuture: false,
    src: Keys.MemberList,
  },
  [Keys.ParentSafesport]: {
    title: 'Parent Safesport',
    info: 'Date completed',
    type: 'date',
    allowFuture: false,
    src: Keys.MemberList,
  },
  [Keys.CodeOfConduct]: {
    title: 'Code of Conduct Expires',
    label: 'Code of Conduct',
    info: 'Expiration Date',
    type: 'date',
    allowFuture: true,
    src: Keys.MemberList,
  },
  [Keys.SwimTest]: {
    title: 'Swim Test',
    info: 'Date received',
    type: 'date',
    src: Keys.MemberList,
  },
  [Keys.Physical]: {
    title: 'Physical',
    info: 'Date received',
    type: 'date',
    src: Keys.MemberList,
  },
  [Keys.Note]: { title: 'Note', type: 'string', src: Keys.MemberList },
  [Keys.TimeSlot]: {
    title: 'Time Slot',
    type: 'enum',
    // label: 'Preferred Time Slot',
    // info: 'Athletes can row in any time slot.  Please indicate which time slot is your preferred time.',
    // enums: [
    //   { key: 'Morning', title: 'Morning -  6:00AM' },
    //   { key: 'MidMorning', title: 'Mid Morning - 7:30AM' },
    //   { key: 'LateMorning', title: 'Late Morning -  9:15AM' },
    //   { key: 'Evening', title: 'Evening - 6:30PM' }
    // ],
    src: Keys.RegList,
  },
  [Keys.Volunteering]: {
    title: 'Volunteer options',
    type: 'checkboxmulti',
    label: 'Volunteering',
    enums: [
      { key: 'Auction', title: 'Auction' },
      { key: 'Admin', title: 'Office/Admin' },
      { key: 'Maintenance', title: 'Maintenance' },
      { key: 'Sorticulture', title: 'Sorticulture' },
      { key: 'Flower Sale', title: 'Holiday Flower Sale' },
      { key: 'Fundraising', title: 'Fundraising' },
    ],
    src: Keys.RegList,
  },
  [Keys.HowFound]: {
    title: 'How Found',
    type: 'enum',
    label: 'Found by',
    enums: [
      { key: 'Friend', title: 'Referred by friend' },
      { key: 'Relative', title: 'Referred by relative' },
      { key: 'SchoolFlyer', title: 'School Flyer' },
      { key: 'BusAd', title: 'Bus Ad' },
      { key: 'TheaterAd', title: 'Theater Ad' },
      { key: 'River', title: 'Saw you on the river' },
      { key: 'PostCard', title: 'Post Card Mailer' },
    ],
    src: Keys.MemberList,
  },
};

export const MemberKeys = Object.keys(DefaultFieldConfig).filter(
  (field) => DefaultFieldConfig[field].src === Keys.MemberList
);
export const RegistrationKeys = Object.keys(DefaultFieldConfig).filter(
  (field) => DefaultFieldConfig[field].src === Keys.RegList
);

export const CurrencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
