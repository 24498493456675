import * as Keys from '../shared/Keys';
import { PageSection } from '../registration/RegistrationTypes';
import { VerifyPhone } from '../registration/VerifyPhone';
import ConfirmRegistration from '../registration/ConfirmRegistration';
import Payment from '../registration/Payment';

const intro = require('../data/juniorInto.html');
const final = require('../data/juniorFinal.html');

export const RegistrationConfig: PageSection[] = [
  [
    {
      title: 'Program Info',
      html: intro.html,
    },
    {
      title: 'Program Selection',
      keys: [Keys.Program],
      fieldConfig: {
        [Keys.Program]: {
          title: 'Program',
          label: '',
          type: 'radio',
          enumList: ['2022 Summer Racing Team'],
          // enumList: ['2022 Spring Racing Team', '2022 Spring Middle School Team'],
          // enumList: ['2021 Winter Conditioning'],
          // enumList: ['2021 Fall High School Team', '2021 Fall Middle School Team'],
          src: Keys.RegList,
        },
      },
    },
    {
      title: '',
      showIf: "'{Program}'.includes('xRacing')",
      keys: [Keys.TimeSlot],
      fieldConfig: {
        [Keys.TimeSlot]: {
          title: 'Session',
          type: 'enum',
          label: 'Time',
          enums: [
            { key: 'Session 0', title: '1:30-3:00PM' },
            { key: 'Session 1', title: '3:00-4:30PM' },
            { key: 'Session 2', title: '4:30-6:00PM' },
          ],
          src: Keys.RegList,
        },
      },
    },
    {
      title: '',
      showIf: "'{Program}'.includes('Racing') || '{Program}'.includes('High')",
      keys: [Keys.Team],
      fieldConfig: {
        // A subset of the list
        [Keys.Team]: {
          title: 'Team',
          type: 'enum',
          label: 'Rowing Squad',
          info: 'Varsity athletes have finished a spring season and competed at regionals.',
          enums: [
            { key: 'Novice', title: 'Novice' },
            { key: 'Varsity', title: 'Varsity' },
          ],
          src: Keys.RegList,
        },
      },
    },
    {
      title: 'Payment Option',
      showIf: "'{Program}'.includes('Singles')",
      keys: [Keys.PaymentOption],
      fieldConfig: {
        [Keys.PaymentOption]: {
          title: '',
          label: '',
          type: 'radio',
          // enumList: [
          //   '$1,552 - Full Payment (rower)',
          //   '$1,164 - Full Payment (coxswain)',
          //   '$776 - Initial payment of two payments',
          //   '$776 - Deposit pending financial aid',
          // ],
          enumList: ['$100 - Full Payment'],
          src: Keys.RegList,
        },
      },
    },
    {
      title: 'Payment Option',
      showIf: "'{Program}'.includes('Conditioning')",
      keys: [Keys.PaymentOption],
      // fieldConfig: {
      //   [Keys.PaymentOption]: {
      //     title: '',
      //     label: '',
      //     type: 'radio',
      //     enumList: ['$800 - Full Payment', '$200 - Deposit pending financial aid'],
      //     src: Keys.RegList,
      //   },
      // },
      props: {},
      fieldConfig: {
        [Keys.PaymentOption]: {
          title: 'Payment Option',
          label: '',
          type: 'radio',
          //enumList: ['$0 - Wait List'],
          enumList: ['$275 - Full Payment', '$140 - Deposit pending financial aid'],
          src: Keys.RegList,
        },
      },
    },
    {
      title: 'Payment Option',
      showIf: "'{Program}'.includes('Summer Racing')",
      keys: [Keys.PaymentOption],
      fieldConfig: {
        [Keys.PaymentOption]: {
          title: '',
          label: '',
          type: 'radio',
          enumList: ['$600 - Full Payment (rower/cox)', '$300 - Deposit pending financial aid'],
          src: Keys.RegList,
        },
      },
    },
    {
      title: 'Payment Option',
      showIf: "'{Program}'.includes('Spring Racing')",
      keys: [Keys.PaymentOption],
      fieldConfig: {
        [Keys.PaymentOption]: {
          title: '',
          label: '',
          type: 'radio',
          enumList: [
            '$1,852 - Full Payment (rower)',
            '$1,389 - Full Payment (coxswain)',
            '$926 - Initial payment of two payments',
            '$926 - Deposit pending financial aid',
          ],
          // enumList: [
          //   '$1,552 - Full Payment (rower)',
          //   '$1,164 - Full Payment (coxswain)',
          //   '$776 - Initial payment of two payments',
          //   '$776 - Deposit pending financial aid',
          // ],
          src: Keys.RegList,
        },
      },
    },
    {
      title: '',
      showIf: "'{Program}'.indexOf('Middle')>0",
      keys: [Keys.PaymentOption],
      //props: { [Keys.TimeSlot]: 'WaitList' },
      fieldConfig: {
        [Keys.PaymentOption]: {
          title: 'Payment Option',
          label: '',
          type: 'radio',
          // enumList: ['$0 - Wait List'],
          enumList: ['$550 - Full Payment (rower/cox)', '$275 - Deposit pending financial aid'],
          src: Keys.RegList,
        },
      },
    },
  ],
  [
    {
      title: 'Athlete Name',
      info: 'Enter the name and birthdate of the athlete you are registering.',
      keys: [Keys.First, Keys.Last, Keys.Birthdate, Keys.ReturningAthlete],
      optionals: [Keys.ReturningAthlete],
    },
  ],
  // [
  //   {
  //     title: 'Sign Waivers',
  //     component: SignWaivers,
  //     disableNext: true,
  //   },
  // ],
  [
    {
      title: 'Account Validation',
      component: VerifyPhone,
      disableNext: true,
    },
  ],
  [
    {
      title: 'Athlete Details',
      info: 'Enter Athlete email and phone number.  This is used to contact the athlete about late breaking scheduling issues or away regattas.  The parent contact info is listed on a different page.  If the Athlete does not have a phone or email, use the parent phone or email.',
      keys: [Keys.Gender, Keys.Email, Keys.Phone, Keys.School, Keys.OtherSchool, Keys.HSGrad],
      optionals: [Keys.OtherSchool],
    },
  ],
  [
    {
      title: 'Address',
      keys: [Keys.Address1, Keys.Address2, Keys.City, Keys.State, Keys.Zip],
      optionals: [Keys.Address2],
    },
  ],
  [
    {
      title: 'Medical',
      info: 'Please provide medical information regarding the athlete. This information is used in the case of injury or medical emergency.  Please also contact the ERA director if there are issues that my require special attention.',
      keys: [Keys.Allergies, Keys.MedCond, Keys.Meds],
      optionals: [Keys.Allergies, Keys.MedCond, Keys.Meds],
    },
  ],
  [
    {
      title: 'Contacts',
      label: 'Parent / Guardian Contact',
      info: 'Enter contact information for the primary parent or guardian of the athlete. A secondary contact is provided on the next page.',
      keys: [Keys.ParentFirst, Keys.ParentLast, Keys.ParentEmail, Keys.ParentPhone],
    },
    {
      title: 'Secondary Contact',
      info: 'Enter the contact information for a secondary emergency contact.  This contact will be used in the event the primary contact is unavailable in an emergency.',
      keys: [
        Keys.SecondaryFirst,
        Keys.SecondaryLast,
        Keys.SecondaryEmail,
        Keys.SecondaryEmailList,
        Keys.SecondaryPhone,
        Keys.SecondaryRelation,
      ],
      optionals: [
        Keys.SecondaryFirst,
        Keys.SecondaryLast,
        Keys.SecondaryEmail,
        Keys.SecondaryPhone,
        Keys.SecondaryEmailList,
        Keys.SecondaryRelation,
      ],
    },
  ],
  [
    {
      title: 'ERA Policy Agreement',
      html: final.html,
      keys: [Keys.PolicyAccept],
      hideNext: true,
    },
    {
      title: '',
      keys: [Keys.Volunteering],
      optionals: [Keys.Volunteering],
      info: 'Please indicate how you can help Everett Rowing. We depend heavily on unpaid volunteers and appreciate your help!',
    },
    {
      title: 'Summary',
      component: ConfirmRegistration,
    },
  ],
  [
    {
      title: 'Payment',
      hideNext: true,
      hidePrev: true,
      component: Payment,
    },
  ],
];
