import { ArgMap } from '../shared/Types';
import * as Keys from '../shared/Keys';
export function uniqifyRegistrations(rows: ArgMap[]) {
  // Sort by submission date so uniq will use last entered
  rows = rows.sort((a, b) => {
    return (a[Keys.Sid] || '').localeCompare(b[Keys.Sid] || '');
  });

  // eliminate duplicates, merge some data
  let uniq: ArgMap<ArgMap> = {};
  rows.forEach(row => {
    uniq[`${row[Keys.ProgramYear]}-${row[Keys.Program]}-${row[Keys.First]}-${row[Keys.Last]}`.toLowerCase()] = row;
  });
  // Sort for presentation purposes
  rows = Object.values(uniq).sort((a, b) => {
    const cmp = (a[Keys.Last] || '').toLowerCase().localeCompare((b[Keys.Last] || '').toLowerCase());
    return cmp !== 0
      ? cmp
      : (a[Keys.ProgramYear] || '').toLowerCase().localeCompare((b[Keys.ProgramYear] || '').toLowerCase());
  });
  return rows;
}

export const formatYMD = (year: number, month: number, day: number) => {
  const s = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
  return s;
};

export const formatDate = (d: Date) => {
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  return formatYMD(year, month, day);
};
export const getDateString = () => {
  return formatDate(new Date());
};

export const validYMD = (s: string) => {
  return s.match(/^[0-9][0-9][0-9][0-9]-[0-9]+-[0-9]+$/);
};

export const validMDY = (s: string) => {
  return s.match(
    /^((0?[13578]|10|12)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[01]?))(-|\/)((19)([0-9])(\d{1})|(20)([0123])(\d{1}))|(0?[2469]|11)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[0]?))(-|\/)((19)([0-9])(\d{1})|(20)([0123])(\d{1})))$/
  );
};

export const convertMDY2YMD = (s: string) => {
  if (validMDY(s)) {
    let parts = s.replace(/\//g, '-').split('-');
    parts = parts.map(p => (p.length === 1 ? `0${p}` : p));
    s = `${parts[2]}-${parts[0]}-${parts[1]}`;
    return s;
  } else {
    return s;
  }
};

export const convertYMD2MDY = (s: string) => {
  if (validYMD(s)) {
    let parts = s.replace(/\//g, '-').split('-');
    parts = parts.map(p => (p.length === 1 ? `0${p}` : p));
    s = `${parts[1]}-${parts[2]}-${parts[0]}`;
    return s;
  } else {
    return s;
  }
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    //let intlCode = match[1] ? '+1 ' : '';
    return `${match[2]}-${match[3]}-${match[4]}`;
  }
  return '';
};

export const curlyReplace = (str: string, map: { [key: string]: any }) => {
  const regexp = /{([^{]+)}/g;
  return str.replace(regexp, function(ignore, key: string) {
    const v = String(map[key]);
    return v === null ? '' : v;
  });
};

/*eslint-disable no-new-func*/
export const saferEval = (str: string) => {
  return Function(`"use strict";return (${str})`)();
};
