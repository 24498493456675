import { ListConfig, FilterField, FieldConfig } from '../shared/Types';
import { RosterTypes as JuniorRosterTypes, JuniorFieldConfig } from './JuniorTypes';
import { RosterTypes as MasterRosterTypes, MastersFieldConfig } from './MasterTypes';
import { RegistrationConfig as JuniorConfig } from './JuniorConfig';
import { RegistrationConfig as MasterConfig } from './MasterConfig';
import { FilterFields as JuniorFilter } from './JuniorTypes';
import { FilterFields as MasterFilter } from './MasterTypes';
import { useGlobalPropertyWithDefault } from '../hooks/UseGlobalProperty';
import { PageSection } from '../registration/RegistrationTypes';
import { DefaultFieldConfig } from './FieldTypes';
export const RosterConfig: { [key: string]: ListConfig[] } = {
  juniors: JuniorRosterTypes,
  masters: MasterRosterTypes,
};

export const RegistrationConfigs: { [key: string]: PageSection[] } = {
  juniors: JuniorConfig,
  masters: MasterConfig,
};

export const FilterFields: { [key: string]: FilterField[] } = {
  juniors: JuniorFilter,
  masters: MasterFilter,
};

export const FieldConfigs: { [key: string]: FieldConfig } = {
  juniors: { ...DefaultFieldConfig, ...JuniorFieldConfig },
  masters: { ...DefaultFieldConfig, ...MastersFieldConfig },
};
export const useRosterType = () => {
  return useGlobalPropertyWithDefault('rosterType', 'juniors');
};
