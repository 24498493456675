import * as Keys from '../shared/Keys';
import { PageSection } from '../registration/RegistrationTypes';
import { VerifyPhone } from '../registration/VerifyPhone';
import ConfirmRegistration from '../registration/ConfirmRegistration';
import Payment from '../registration/Payment';

const intro = require('../data/mastersIntro.html');
const final = require('../data/mastersFinal.html');

export const RegistrationConfig: PageSection[] = [
  [
    {
      title: 'Program Info',
      html: intro.html,
    },
    {
      title: 'Program Selection',
      keys: [Keys.Program],
      fieldConfig: {
        [Keys.Program]: {
          title: 'Program',
          label: '',
          type: 'radio',
          enumList: [
            'Annual Coached Program ($1200)',
            'Quarterly Coached Program ($360)',
            'Monthly New Rower Program ($120) Starting April-December',
            'Single Boat Storage ($350)',
            'Double/Pair Boat Storage ($450)',
            'Other',
          ],
          src: Keys.RegList,
        },
      },
    },
    {
      title: '',
      showIf: "'{Program}'.includes('Program')",
      keys: [Keys.TimeSlot],
      fieldConfig: {
        [Keys.TimeSlot]: {
          title: 'Time Slot',
          type: 'enum',
          label: 'Preferred Time Slot',
          info: 'Athletes can row in any time slot.  Please indicate which time slot is your preferred time.',
          enums: [
            { key: 'Morning', title: 'Morning -  6:00AM' },
            { key: 'MidMorning', title: 'Mid Morning - 7:30AM' },
            { key: 'LateMorning', title: 'Late Morning -  9:15AM' },
            { key: 'Evening', title: 'Evening - 6:30PM' },
          ],
          src: Keys.RegList,
        },
      },
    },
  ],
  [
    {
      title: 'Athlete Name',
      info: 'Enter the name and birthdate of the athlete you are registering.',
      keys: [Keys.First, Keys.Last, Keys.Birthdate, Keys.ReturningAthlete],
      optionals: [Keys.ReturningAthlete],
    },
  ],
  [
    {
      title: 'Account Validation',
      component: VerifyPhone,
      disableNext: true,
    },
  ],
  [
    {
      title: 'Athlete Details',
      info: 'Enter Athlete email and phone number.',
      keys: [Keys.Gender, Keys.Email, Keys.Phone],
    },
  ],
  [
    {
      title: 'Address',
      keys: [Keys.Address1, Keys.Address2, Keys.City, Keys.State, Keys.Zip],
      optionals: [Keys.Address2],
    },
  ],
  [
    {
      title: 'Medical',
      info: 'Please provide medical information regarding the athlete. This information is used in the case of injury or medical emergency.  Please also contact the ERA director if there are issues that my require special attention.',
      keys: [Keys.Allergies, Keys.MedCond, Keys.Meds],
      optionals: [Keys.Allergies, Keys.MedCond, Keys.Meds],
    },
  ],
  [
    {
      title: 'Contacts',
      label: 'Emergency Contact',
      info: 'This is for a spouse, friend, or relation to be contacted in case of emergency.',
      keys: [Keys.EmFirst, Keys.EmLast, Keys.EmPhone, Keys.EmRelationship],
    },
  ],
  [
    {
      title: 'ERA Policy Agreement',
      html: final.html,
      keys: [Keys.MastersPolicyAccept],
      hideNext: true,
    },
    {
      title: '',
      keys: [Keys.Volunteering],
      optionals: [Keys.Volunteering],
      info: 'Please indicate how you can help Everett Rowing. We depend heavily on unpaid volunteers and appreciate your help!',
    },
    {
      title: 'Summary',
      component: ConfirmRegistration,
    },
  ],
  [
    {
      title: 'Payment',
      hideNext: true,
      hidePrev: true,
      component: Payment,
    },
  ],
];
