import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  TextField,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Checkbox,
  FormGroup,
  Divider,
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from './UseForm';
import { formatDate, curlyReplace, saferEval, validMDY, convertYMD2MDY, convertMDY2YMD } from './FormUtils';
import { EnumDef, FieldConfig, DateField } from './FormTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  label: {
    paddingRight: theme.spacing(2),
  },
  textField: {
    width: 250,
  },
  menu: {
    width: 250,
  },
  selectField: {
    width: 250,
  },
  dateField: {
    width: 250,
  },
  radioField: {},
  checkboxField: {},
  group: {
    margin: theme.spacing(1, 0),
  },
  error: {
    color: theme.palette.error.dark,
  },
  header: {
    marginTop: theme.spacing(1),
  },
  divider: { marginBottom: theme.spacing(1) },
}));

function getValue(values: any, path: string, fallback?: any) {
  fallback = fallback || '';
  if (path.indexOf('.') === -1) {
    const value = values[path];
    return value === undefined ? fallback : String(value);
  }
  const parts = path.split('.');
  for (const key of parts) {
    values = values[key];
    if (values === undefined) {
      return fallback;
    }
  }
  return String(values);
}

export const StringX = (props: { name: string; label?: string }) => {
  const classes = useStyles();
  const { values, errors, setValue } = useForm();
  const { name, label } = props;
  const value = getValue(values, name, '');
  const handleChange = (event: any) => {
    const v = event.target.value;
    setValue(name, v);
  };
  return (
    <TextField
      error={Boolean(errors[name])}
      className={classes.textField}
      variant="outlined"
      label={label}
      margin="dense"
      value={value}
      onChange={handleChange}
    />
  );
};

export const DatePickerX = (props: { name: string; label?: string; info?: string; field?: DateField }) => {
  const classes = useStyles();
  const { values, setValue } = useForm();
  const { name, field, label, info } = props;
  const value = getValue(values, name, '').trim();
  const allowFuture = Boolean(field && field.allowFuture);
  const handleChange = (d: Date | null) => {
    if (d) {
      setValue(name, formatDate(d));
    } else {
      setValue(name, '');
    }
  };
  return (
    <DatePicker<Date>
      // error={Boolean(errors[name])}
      className={classes.dateField}
      clearable
      // autoOk
      //inputVariant="outlined"
      // variant="dialog"
      // margin="dense"
      label={label}
      // maskChar="_"
      // emptyLabel=""
      value={(value && new Date(value + 'T00:00:00')) || null}
      onChange={handleChange}
      minDate={new Date('1920/01/01')}
      maxDate={allowFuture ? undefined : new Date()}
      //format="MM/DD/YYYY"
      // helperText={info}
      renderInput={(params: object) => <TextField {...params} helperText={info} />}
    />
  );
};

export const DateX = (props: { name: string; label?: string; info?: string; field?: DateField }) => {
  const classes = useStyles();
  const { values, errors, setValue } = useForm();
  const { name, label, info } = props;
  const handleChange = (event: any) => {
    const v = event.target.value;
    setValue(name, convertMDY2YMD(v));
  };
  const value = convertYMD2MDY(getValue(values, name, '').trim());
  const error = value && !validMDY(value);
  return (
    <TextField
      error={Boolean(errors[name] || error)}
      className={classes.textField}
      variant="outlined"
      label={value ? `${label} dd/mm/yyyy` : label}
      placeholder="MM/DD/YYYY"
      margin="dense"
      value={value}
      onChange={handleChange}
      helperText={errors[name] || info}
    />
  );
};
export const SelectX = (props: {
  name: string;
  mapping: { key: string; title: string }[];
  defaultValue?: string;
  label?: string;
  info?: string;
}) => {
  const classes = useStyles();
  const { values, errors, setValue } = useForm();
  const { name, label, info, mapping, defaultValue } = props;

  function handleChange(event: any) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setValue(name, value);
  }
  const value = (values[name] || defaultValue || '').trim();
  return (
    <TextField
      error={Boolean(errors[name])}
      select
      label={label}
      className={classes.selectField}
      value={value}
      onChange={handleChange}
      SelectProps={{
        autoWidth: true,
        // MenuProps: {
        //   className: classes.menu
        // }
      }}
      helperText={info}
      margin="dense"
      variant="outlined"
      // style={{ width: 300 }}
    >
      {mapping.map((item) => (
        <MenuItem key={item.title} value={item.key}>
          {item.title}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const RadioX = (props: {
  name: string;
  mapping: { key: string; title: string }[];
  defaultValue?: string;
  label?: string;
  info?: string;
}) => {
  const classes = useStyles();
  const { values, errors, setValue } = useForm();
  const { name, label, mapping, defaultValue } = props;

  function handleChange(event: any) {
    const value = event.target.value;
    setValue(name, value);
  }
  const value = (values[name] || defaultValue || '').trim();
  const error = Boolean(errors[name]);
  return (
    <FormControl component="fieldset" className={classes.radioField}>
      <FormLabel error={error} component="legend">
        {label}
      </FormLabel>
      <RadioGroup name={name} className={classes.group} value={value} onChange={handleChange}>
        {mapping.map((item) => (
          <FormControlLabel
            className={error ? classes.error : undefined}
            key={item.title}
            value={item.key}
            control={<Radio />}
            label={item.title}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export const CheckboxX = (props: { name: string; label?: string; info?: string }) => {
  const classes = useStyles();
  const { values, errors, setValue } = useForm();
  const { name, label, info } = props;
  function handleChange(event: any) {
    const checked = event.target.checked;
    setValue(name, checked ? 'true' : 'false');
  }
  const value = values[name] === 'true';
  const error = Boolean(errors[name]);
  return (
    <FormControl component="fieldset" className={classes.checkboxField}>
      <FormGroup>
        <FormControlLabel
          className={error ? classes.error : undefined}
          control={<Checkbox checked={value} onChange={handleChange} value={label} />}
          label={label || ''}
        />
      </FormGroup>
      <FormHelperText>{info}</FormHelperText>
    </FormControl>
  );
};

export const MultiCheckboxX = (props: {
  name: string;
  mapping: { key: string; title: string }[];
  defaultValue?: string;
  label?: string;
  info?: string;
}) => {
  const classes = useStyles();
  const { values, errors, setValue } = useForm();
  const { name, label, info, mapping, defaultValue } = props;
  const [checks, setChecks] = useState<string[]>([]);
  const handleChange = (key: string) => (event: any) => {
    let checked = event.target.checked;
    setChecks((prior) => {
      const newChecks = prior.filter((val) => val !== key && val !== '');
      if (checked) {
        newChecks.push(key);
      }
      setValue(name, newChecks.join(','));
      return newChecks;
    });
  };
  useEffect(() => {
    setChecks((values[name] || defaultValue || '').trim().split(','));
  }, [values, defaultValue, name]);

  const error = Boolean(errors[name]);
  return (
    <FormControl component="fieldset" className={classes.checkboxField}>
      <FormLabel error={error} component="legend">
        {label}
      </FormLabel>
      <FormGroup>
        {mapping.map((item) => (
          <FormControlLabel
            key={item.key}
            className={error ? classes.error : undefined}
            control={
              <Checkbox
                checked={Boolean(checks.includes(item.key))}
                onChange={handleChange(item.key)}
                value={item.key}
              />
            }
            label={item.title}
          />
        ))}
      </FormGroup>
      <FormHelperText>{info}</FormHelperText>
    </FormControl>
  );
};
export const FormValues = (props: { keys: string[]; fieldConfig: FieldConfig; twocol?: boolean }) => {
  const { keys, fieldConfig, twocol } = props;
  const classes = useStyles();
  const { values } = useForm();
  const renderItem = (key: string) => {
    let field = fieldConfig[key];
    if (!field) {
      if (key.startsWith('--')) {
        field = { title: key.replace(/^-* */, ''), type: 'section', src: 'registrations' };
      } else {
        field = { title: key, type: 'string', src: 'registrations' };
      }
    }
    const label = field.label || field.title;
    const info = field.info;
    if (!field) return <Typography>{`Missing:${key} def`}</Typography>;

    switch (field.type) {
      case 'radio': {
        let enums: EnumDef[] = field.enums || [];
        if (field.enumList) {
          enums = field.enumList.map((name) => ({ title: name, key: name } as EnumDef));
        }
        return (
          <RadioX
            name={key}
            label={label}
            info={info}
            //defaultValue={enums[0].key}
            mapping={enums}
          />
        );
      }
      case 'enum': {
        let enums: EnumDef[] = field.enums || [];
        if (field.enumList) {
          enums = field.enumList.map((name) => ({ title: name, key: name } as EnumDef));
        }
        return <SelectX name={key} label={label} info={info} defaultValue={field.default} mapping={enums} />;
      }
      case 'checkboxmulti': {
        let enums: EnumDef[] = field.enums || [];
        if (field.enumList) {
          enums = field.enumList.map((name) => ({ title: name, key: name } as EnumDef));
        }
        return (
          <MultiCheckboxX
            name={key}
            label={label}
            info={info}
            //defaultValue={enums[0].key}
            mapping={enums}
          />
        );
      }
      case 'date': {
        // return <DatePickerX name={key} label={label} field={field} info={info} />;
        return <DateX name={key} label={label} field={field} info={info} />;
      }
      case 'timestamp': {
        const d = new Date(Number(values[key]));
        return <Typography>{`xx${d.toLocaleString()}`}</Typography>;
      }
      case 'string': {
        return <StringX name={key} label={label} />;
      }
      case 'checkbox': {
        return <CheckboxX name={key} label={label} info={info} />;
      }
      case 'section': {
        return (
          <Grid container spacing={0}>
            <Grid item xs={12} className={classes.header}>
              <Typography align="left">{field.title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
        );
      }
      default: {
        return <StringX name={key} label={label} />;
      }
    }
  };

  if (twocol) {
    return (
      <React.Fragment>
        {keys.map((col) => (
          <React.Fragment key={col}>
            <Grid item xs={6}>
              <Typography align="right" className={classes.label}>
                {fieldConfig[col].title}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {renderItem(col)}
            </Grid>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {keys.map((col) => {
        const field = fieldConfig[col];
        if (field && field.showIf) {
          const expr = curlyReplace(field.showIf, values);
          const show = saferEval(expr);
          if (!show) {
            return null;
          }
        }
        return (
          <Grid key={col} item xs={12}>
            {renderItem(col)}
          </Grid>
        );
      })}
    </React.Fragment>
  );
};
