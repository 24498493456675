import * as Keys from '../shared/Keys';
import { ArgMap } from '../shared/Types';
const ROSTERID = 'External Constituent ID #2';

const mapping = [
  [Keys.MemberId, ROSTERID],
  [Keys.Last, Keys.Last],
  [Keys.First, Keys.First],
  [Keys.Address1, 'Address line 1'],
  [Keys.City, Keys.City],
  [Keys.State, Keys.State],
  [Keys.Zip, Keys.Zip],
  [Keys.Email, Keys.Email],
  [Keys.Phone, Keys.Phone],
  [Keys.Gender, Keys.Gender],
  [Keys.Birthdate, 'Birthday'],
  [Keys.Allergies, Keys.Allergies],
  [Keys.MedCond, Keys.MedCond],
  [Keys.Meds, Keys.Meds],
  [Keys.Program, Keys.Program],
  [Keys.Team, Keys.Team],
  ['Groups', 'Groups'],
  [Keys.HSGrad, 'Class affiliation grad. year'],
  [Keys.School, Keys.School],
  ['Start', 'Membership start date'],
  ['End', 'Membership end date'],
  ['Membership level', 'Membership level'],
  [Keys.TimeSlot, Keys.TimeSlot],

  [Keys.ParentLast, 'ParentLast'],
  [Keys.ParentFirst, 'ParentFirst'],
  [Keys.Address1, 'ParentAddress line 1 '],
  [Keys.City, 'ParentCity'],
  [Keys.State, 'ParentState'],
  [Keys.Zip, 'ParentZip'],
  [Keys.ParentEmail, 'ParentEmail'],
  [Keys.ParentPhone, 'ParentPhone'],
  [Keys.SecondaryRelation, 'Relationship to'],
  ['ParentGroups', 'ParentGroups'],
  [Keys.HSGrad, 'ParentGradYear'],

  [Keys.SecondaryLast, '2ndLast'],
  [Keys.SecondaryFirst, '2ndFirst'],
  [Keys.SecondaryEmail, '2ndEmail'],
  [Keys.SecondaryPhone, '2ndPhone'],
  [Keys.SecondaryRelation, '2ndRelationship to'],
  [Keys.HSGrad, '2ndGradYear'],

  [Keys.EmLast, '3rdLast'],
  [Keys.EmFirst, '3rdFirst'],
  [Keys.EmEmail, '3rdEmail'],
  [Keys.EmPhone, '3rdPhone'],
  [Keys.EmRelationship, 'Relationship'],
];

export function generateLGLExport(rosterType: string, list: ArgMap[]) {
  const list2 = list.map((item) => {
    if (item[Keys.School] === 'Other') {
      item[Keys.School] = item[Keys.OtherSchool];
    }
    item['Membership level'] = rosterType === 'juniors' ? 'Junior Program' : item['Membership level'] || '';
    item['Groups'] = rosterType === 'juniors' ? 'Juniors' : 'Masters';
    item['2ndGroups'] = 'Parents';
    const program = item[Keys.Program];
    const year = item[Keys.ProgramYear];
    let start = `${year}-01-01`;
    let end = `${year}-07-01`;
    if (program.includes('Summer')) {
      start = `${year}-07-01`;
      end = `${year}-09-01`;
    } else if (program.includes('Fall')) {
      start = `${year}-09-01`;
      end = `${year}-12-31`;
    } else if (rosterType === 'masters') {
      start = `${year}-01-01`;
      end = `${year}-12-31`;
    }
    item.Start = start;
    item.End = end;

    const entry = mapping.map(function (map) {
      return item[map[0]] || '';
    });
    return entry;
  });

  const headers = `"${mapping
    .map(function (map) {
      return map[1];
    })
    .join('","')}"`;
  const rows = list2
    .map(function (line) {
      return `"${line.join('","')}"`;
    })
    .join('\n');
  return `${headers}\n${rows}`;
}
