import { Box, Button, CircularProgress, Divider, Paper, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import useToast from './hooks/UseToast';
import { useIsAdmin } from './hooks/UseUserState';
import { ArgMap } from './shared/Types';
import { generateAuthURI, matchQboCustomers, refreshQboCustomers } from './util/qb/QbUtil';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: theme.spacing(3),
  },
}));
export const AccountingPage = () => {
  const classes = useStyles();
  const [match, setMatch] = useState<ArgMap | undefined>();
  const [text, setText] = useState<string | undefined>();
  const [showProgress, setShowProgress] = useState(false);
  const [authURI, setAuthURI] = useState('');
  const [, isSuperAdmin] = useIsAdmin();
  const { success, failure } = useToast();
  const onMatchAccounts = () => {
    setShowProgress(true);
    setMatch(undefined);
    setText(undefined);
    matchQboCustomers()
      .then((results) => {
        console.log(results);
        setMatch(results);
      })
      .catch((e) => {
        failure(e instanceof Error ? e.message : String(e));
      })
      .finally(() => setShowProgress(false));
  };

  const onRefreshAccounts = () => {
    setShowProgress(true);
    setMatch(undefined);
    setText(undefined);
    refreshQboCustomers()
      .then((results) => {
        console.log(results);
        const msg = `${results.updates} accounts updated`;
        success(msg);
        setText(msg);
      })
      .catch((e) => {
        failure(e instanceof Error ? e.message : String(e));
      })
      .finally(() => setShowProgress(false));
  };

  const onInvokeAuthURI = () => {
    window.open(authURI, '_blank');
  };

  const onGenAuthURI = () => {
    setShowProgress(true);
    setMatch(undefined);
    setText(undefined);
    generateAuthURI()
      .then((results) => {
        console.log(results);
        setAuthURI(results.authUri);
        setText(JSON.stringify(results));
      })
      .catch((e) => {
        failure(e instanceof Error ? e.message : String(e));
      })
      .finally(() => setShowProgress(false));
  };
  const renderMatch = () => {
    const view = [];
    for (const roster in match) {
      const result = match[roster];
      const render = (
        <div id={roster}>
          <div>{roster}</div>
          <div>{`Total: ${result.count}, updated: ${result.updated}, no match: ${result.failed}`}</div>
        </div>
      );
      view.push(render);
    }
    return <Paper sx={{ margin: '16px', padding: '8px' }}>{view}</Paper>;
  };

  return (
    <div className={classes.root}>
      <Stack>
        <Typography>Use the actions on this page to manage the integration with Quickbooks Online.</Typography>
        <Typography>- "Refresh Balance" will retrieve current balance data from QBO.</Typography>
        <Typography>
          - "Auto Match new QBO Accounts" will link the roster data with newly created QBO accounts.
        </Typography>
        <Box sx={{ margin: '8px' }} />

        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
          <Button variant="contained" color="primary" onClick={onRefreshAccounts}>
            Refresh Balance data from QBO
          </Button>
          <Button variant="contained" color="primary" onClick={onMatchAccounts}>
            Auto Match new QBO Accounts
          </Button>
          {isSuperAdmin && (
            <Button variant="contained" color="primary" onClick={onGenAuthURI}>
              Generate Auth URI
            </Button>
          )}
          {isSuperAdmin && authURI && (
            <Button variant="contained" color="primary" onClick={onInvokeAuthURI}>
              Invoke Auth URI
            </Button>
          )}
        </Stack>
        {showProgress && <CircularProgress sx={{ margin: '16px' }} />}
        {match && renderMatch()}
        {text && (
          <Paper sx={{ margin: '16px', padding: '16px' }}>
            <Typography>{text}</Typography>
          </Paper>
        )}
      </Stack>
    </div>
  );
};
export default AccountingPage;
