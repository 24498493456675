module.exports = { html:`
<div class="lgld-field-display">
  <div class="lgld-form-title">Everett Rowing Youth Registration</div>
  <div class="lgld-form-desc">
    <p>ERA program fees cover coaching and racing expenses for the season. Special trips may be an additional expense.</p>
    <p><b>Program Dates for Summer Racing Team</b></p>
    <b>Varsity</b>
    <bl>
      <li>June 20, 2022 - Aug 19, 2022</li>
      <li>Monday-Thursday 7:30 - 9:30am <b>and</b> 3:00-5:00pm</li>
    </bl>
    <p></p>
    <b>Novice</b>
    <bl>
      <li>June 20, 2022 - Aug 19, 2022</li>
      <li>Monday-Thursday 7:30 - 9:30am</li>
      <li>Optional: Monday-Thursday 3:00 - 5:00pm</li>
    </bl>
    <p></p>


    <p>See <website href="https://www.everettrowing.com/high-school-team">website</website</a> for more details. Financial Aid Forms are due the first week of practice for every season.</p>
    <p>All Rowers must have</p>
    <ol>
      <li>
        Annual&nbsp;<strong>Printed USRowing Waiver</strong>&nbsp;on file with ERA
      </li>
      <li>
        Annual&nbsp;<strong>Electronic USRowing Waiver</strong>&nbsp;
      </li>
      <li>
        One time completed&nbsp;<strong>Swim Test Form</strong>
      </li>
      <li>
        <span style="color: #000000;"
          >Completed <strong>Athletic Physical</strong> (1 since the age of 14) - any form from doctor or school office
          ok.</span
        >
      </li>
      <li>
        Annual&nbsp;<strong>Athlete Code of Conduct</strong>
      </li>
      <li>
        Annual&nbsp;<strong>Medical Release Form</strong>
      </li>

      <!-- <li>
        <COVID19 style="color: #ff0000;">COVID19 liability release
      </li> -->
    </ol>
    <p>
      <span style="color: #000000;"
        >These forms are available on our&nbsp;<a href="https://www.everettrowing.com/forms-policies"
          >Forms and Policies</a
        >
        page.</span
      >
    </p>
  </div>
</div>
` }
