import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import { useDataList } from './UseDataList';
interface AdminInfo {
  email: string;
  admin?: boolean;
  rosterOnly?: boolean;
}
export const useUserState = () => {
  const [user, loading, error] = useAuthState(firebase.auth());
  return [user, loading, error] as [typeof user, typeof loading, typeof error];
};

/**
 * Return [isAdmin, isSuperAdmin]
 */
export const useIsAdmin = () => {
  const [user] = useAuthState(firebase.auth());
  const { list: adminList } = useDataList<AdminInfo>('config/admin');
  if (!user || !adminList || !user.email) {
    return [false, false];
  }
  const userInfo = adminList.find((u) => user.email === u.email);
  const isAdmin = Boolean(userInfo && userInfo.admin);
  const isSuperAdmin = isAdmin && user.email === 'glenne@engel.org';
  return [isAdmin, isSuperAdmin];
};
export const useIsRosterOnly = () => {
  const [user] = useAuthState(firebase.auth());
  const { list: adminList } = useDataList<AdminInfo>('config/admin');
  if (!user || !adminList || !user.email) {
    return [false, false];
  }
  const userInfo = adminList.find((u) => user.email === u.email);
  const isRosterOnly = Boolean(userInfo && userInfo.rosterOnly);
  return [isRosterOnly];
};
export default useUserState;
