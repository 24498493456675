import React from 'react';
import { FieldConfig } from '../shared/Types';
import { Section } from './RegistrationTypes';
import { Grid, Typography } from '@mui/material';
import { FormValues } from '../util/FormX';

export const RegistrationPage = (props: {
  section: Section;
  fieldConfig: FieldConfig;
  onNext: () => void;
}) => {
  const { keys, info, html, component: Component } = props.section;
  const { fieldConfig, onNext } = props;
  const hasInfo = Boolean(info);
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
      </Grid>
      <Grid item xs={hasInfo ? 6 : 12}>
        {keys && (
          <Grid container spacing={0}>
            <FormValues keys={keys} fieldConfig={fieldConfig} />
          </Grid>
        )}
      </Grid>
      {hasInfo && (
        <Grid item xs={6}>
          <Typography className={info}>{info}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {Component && <Component onNext={onNext} />}
      </Grid>
    </Grid>
  );
};
