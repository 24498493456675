import axios from 'axios';
import { firebaseConfig } from '../../fire';
import { ArgMap } from '../../shared/Types';
import { Customer } from '../../shared/qbo/QboTypes';

export const matchQboCustomers = async (): Promise<Customer[]> => {
  return new Promise(async (resolve, reject) => {
    const url = `https://${firebaseConfig.authDomain}/qbo-match-customers`;
    const result = await axios.get(url);
    if (result.status !== 200) {
      reject(`Unable to query customer list: ${result.statusText}`);
    }
    resolve(result.data);
  });
};

export const refreshQboCustomers = async (): Promise<{ updates: number }> => {
  console.log('domain = ' + firebaseConfig.authDomain);
  return new Promise(async (resolve, reject) => {
    const url = `https://${firebaseConfig.authDomain}/qbo-refresh-customers`;
    const result = await axios.get(url);
    if (result.status !== 200) {
      reject(`Unable to query customer list: ${result.statusText}`);
    }
    resolve(result.data);
  });
};

export const generateAuthURI = async (): Promise<ArgMap> => {
  return new Promise(async (resolve, reject) => {
    const url = `https://${firebaseConfig.authDomain}/qbo-auth-uri`;
    const result = await axios.get(url);
    if (result.status !== 200) {
      reject(`Unable to query customer list: ${result.statusText}`);
    }
    resolve(result.data as ArgMap);
  });
};
