import React from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';
import makeStyles from '@mui/styles/makeStyles';
import BuildInfo from './buildinfo.json';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(3)
  },
  text: {
    marginLeft: theme.spacing(2)
  }
}));
export const About = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Title>Version Information</Title>
      <Typography
        className={classes.text}
        color="textSecondary"
      >{`Build: ${BuildInfo.build}`}</Typography>
      <Typography
        className={classes.text}
        color="textSecondary"
      >{`Date: ${BuildInfo.date}`}</Typography>
    </div>
  );
};
export default About;
