import React from 'react';
import { LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));
export interface ProgressProps {
  period?: number;
  repeat?: boolean;
}
export const Progress = ({ period = 10000, repeat = true }: ProgressProps) => {
  const [completed, setCompleted] = React.useState(0);
  const classes = useStyles();
  React.useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        if (oldCompleted >= 100) {
          return repeat ? 0 : 100;
        }
        return Math.min(oldCompleted + 100 / (period / 100), 100);
      });
    }
    const timer = setInterval(progress, 100);
    return () => {
      clearInterval(timer);
    };
  }, [period, repeat]);
  return (
    <div className={classes.progress}>
      <LinearProgress variant="determinate" value={completed} />
    </div>
  );
};
