import { ArgMap } from '../shared/Types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useCollectionData, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import * as Keys from '../shared/Keys';
import { useMemo } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { RosterConfig, useRosterType } from '../data/RosterTypes';

// Typical firestore layout:
// juniors/registrations{}/list[]
// juniors/registrations{}/journal[]
// juniors/members{}/list[]
// juniors/members{}/journal[]
// juniors/rostertypes{}/list[] or juniors/rostertypes.list[] or juniors/config.rostertypes[]
// juniors/config
//

/**
 * Update a single document in a collection.  If the document doesn't exist, it is
 * added.  A journal entry is also created.
 * @param dbName The name of the list to update.  e.g. juniors/registrations
 * @param user User credentials if they exist
 * @param doc Document to update in collection with embedded id field
 */
const updateOrCreateListItem = async (
  dbName: string,
  user: firebase.User | undefined,
  doc: ArgMap,
  updateOnly: boolean,
  remove: boolean
) => {
  return new Promise(async (resolve, reject) => {
    const path = `${dbName}/list/${doc.id}`;
    const journalPath = `${dbName}/journal`;
    try {
      doc[Keys.Timestamp] = Date.now();
      doc[Keys.ModifiedBy] = (user && (user.email || user.phoneNumber)) || 'unknown';
      // console.log('updating ' + path);
      if (remove) {
        await firebase.firestore().doc(path).delete();
      } else if (updateOnly) {
        await firebase.firestore().doc(path).update(doc);
      } else {
        await firebase.firestore().doc(path).set(doc);
      }
      await firebase.firestore().collection(journalPath).add(doc);
      resolve(true);
    } catch (e) {
      reject(Error(`Could not write document ${path}. ${String(e)}`));
    }
  });
};

/**
 * Provide a mechanism to update a list item without reading the entire list.
 *
 * @param listName The name of the list to query and watch.  e.g. juniors/registrations
 * @return {update} An update function to modify or create entries.
 */
export function useDataListWriteOnly(listName: string) {
  const [user] = useAuthState(firebase.auth());
  /**
   * Update a single document in a collection.  If the document doesn't exist, it is
   * added.  A journal entry is also created.
   * @param doc Document to update in collection with embedded id field
   */
  const update = async (doc: ArgMap, updateOnly: boolean = false) =>
    updateOrCreateListItem(listName, user, doc, updateOnly, false);
  return { update };
}
/**
 * Query a list and watch for changes.
 * @param listName The name of the list to query and watch.  e.g. juniors/registrations
 * @return {list, update} The list contents and an update function to modify or create entries.
 */
export function useDataList<T = any>(listName: string, updateOnly: boolean = false) {
  const [user] = useAuthState(firebase.auth());
  const [rosterType] = useRosterType();
  // const dbName = 'test-' + listName;
  const dbName = listName;
  let [list] = useCollectionData(firebase.firestore().collection(`${dbName}/list`));
  if (listName.includes('rostertypes')) {
    // for now, this is not stored in database
    list = RosterConfig[rosterType];
  }

  /**
   * Update a single document in a collection.  If the document doesn't exist, it is
   * added.  A journal entry is also created.
   * @param doc Document to update in collection with embedded id field
   */
  const update = async (doc: ArgMap, updateOnly: boolean = false) =>
    updateOrCreateListItem(dbName, user, doc, updateOnly, false);

  const remove = async (id: string) => updateOrCreateListItem(dbName, user, { id }, false, true);
  return { list: (list || []) as T[], update, remove };
}

/**
 * Query a list and watch for changes. Both a list and map are provided.
 * @param listName The name of the list to query and watch.  e.g. juniors/registrations
 * @return {list, map, update} The list contents and an update function to modify or create entries.
 */
export function useDataMap<T = any>(listName: string) {
  const listData = useDataList(listName);
  const result = useMemo(() => {
    const map: ArgMap<T> = {};
    listData.list.forEach((item) => (map[item[Keys.Id]] = item));
    return { ...listData, map };
  }, [listData]);
  return result;
}

export function useDataDocument<T = any>(path: string) {
  const [doc, loading, error] = useDocumentDataOnce<T>(firebase.firestore().doc(path));
  return [doc, loading, error];
}
