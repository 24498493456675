import { useGlobalPropertyWithDefault } from './hooks/UseGlobalProperty';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { ArgMap } from './shared/Types';
import * as Keys from './shared/Keys';
import { formatDate } from './util/RosterUtils';
import { useDataList } from './hooks/UseDataList';
import { useRosterType } from './data/RosterTypes';
import useAlert from './hooks/UseAlertDialog';
//'Addr1,Addr2,Allergies,Amount,Athlete Name - Prefix,Athlete Name - Suffix,Birthdate,Birthdateorig,ChangeType,City,Col45,Col47,Country,CreateTimestamp,Email,First,Gender,HSGrad,Is Recurring,Last,MedCond,Meds,Mid,ModifiedBy,OtherSchool,Parent Name - Prefix,Parent Name - Suffix,ParentEmail,ParentFirst,ParentLast,ParentPhone,Payment Option - Details,Payment Option - Details-6,Payment Option - Details-8,Payment Option - Quantity,Payment Option - Quantity-7,Payment Option - Quantity-9,Payment Status,Phone,Policy Acceptance,Program,School,Secondary Name - Prefix,Secondary Name - Suffix,SecondaryEmail,SecondaryFirst,SecondaryLast,SecondaryPhone,SecondaryRelation,Sid,State,SubmitDate,SubmitDateorig,Team,Timestamp,USRMember,Volunteer options,Waiver,Zip,id,PolicyAccept,Year,RowingAge,ParentName,SecondaryName,EmName'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    maxWidth: 'calc(100% - 96px)',
  },
  label: {
    paddingRight: theme.spacing(2),
  },
}));
interface MergeAccountConfig {
  open: boolean;
  rows?: ArgMap<any>[];
}
export const useMergeAccount = () => {
  return useGlobalPropertyWithDefault<MergeAccountConfig>('MergeAccount', { open: false });
};

const MergeAccounts = () => {
  const classes = useStyles();
  let [{ open, rows: rawRows }, setConfig] = useMergeAccount();
  const [selectedRow, setSelectedRow] = useState(-1);
  const setAlert = useAlert();
  const [rosterType] = useRosterType();
  const { remove: removeMember } = useDataList<ArgMap>(`${rosterType}/members`);
  const { list: registrationList, update: updateRegistration } = useDataList<ArgMap>(`${rosterType}/registrations`);

  const rowList = rawRows ? rawRows : [];
  const members: ArgMap<any> = {};
  rowList.forEach((row) => (members[row[Keys.MemberId]] = row));
  const memberList = Object.values(members);
  const memberIdList = Object.keys(members);
  const onClose = () => {
    setSelectedRow(-1);
    setConfig({ open: false });
  };
  const onApply = async () => {
    const memberId = memberList[selectedRow][Keys.MemberId];
    const registrationUpdate: ArgMap = {
      [Keys.MemberId]: memberId,
      [Keys.ChangeType]: Keys.ChangeEdit,
    };
    const updateList = registrationList.filter(
      (row) => memberId !== row[Keys.MemberId] && memberIdList.includes(row[Keys.MemberId])
    );
    console.log(`Updating ${updateList.length} registrations`);
    for (let i = 0; i < updateList.length; i++) {
      const row = updateList[i];
      console.log('Updating ' + row[Keys.Id]);
      await updateRegistration({ [Keys.Id]: row[Keys.Id], ...registrationUpdate }, true).catch((e) =>
        setAlert({ title: 'Save Failed', message: String(e) })
      );
    }
    for (let i = 0; i < memberList.length; i++) {
      const row = memberList[i];
      if (memberId === row[Keys.MemberId]) {
        console.log('skipping member ' + row[Keys.MemberId]);
        continue;
      }
      console.log('removing member ' + row[Keys.MemberId]);
      await removeMember(row[Keys.MemberId]).catch((e) => setAlert({ title: 'Remove Failed', message: String(e) }));
    }
    onClose();
  };

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={Boolean(open)}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Merge Accounts</DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">Primary</TableCell>
              <TableCell>Last</TableCell>
              <TableCell>First</TableCell>
              <TableCell>Birthdate</TableCell>
              <TableCell>Submission</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(memberList || []).map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                onClick={(event: any) => setSelectedRow(selectedRow === rowIndex ? -1 : rowIndex)}
              >
                <TableCell padding="checkbox">
                  <Checkbox checked={rowIndex === selectedRow} />
                </TableCell>
                <TableCell>{row[Keys.Last]}</TableCell>
                <TableCell>{row[Keys.First]}</TableCell>
                <TableCell>{row[Keys.Birthdate]}</TableCell>
                <TableCell>{row[Keys.SubmitDate]}</TableCell>
                <TableCell>{formatDate(new Date(row[Keys.CreateTimestamp]))}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onApply} color="primary" disabled={selectedRow === -1}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MergeAccounts;
