/** The id in any given list */
export const Id = 'id';
/** The id of a member in the member list, stored with registrations */
export const MemberId = 'Mid';
/** Id of member in Quickbooks Online */
export const QboId = 'qboId';
/** The time (UTC milli) when the record was updated */
export const Timestamp = 'Timestamp';
/** The email of the person updating/creating a record */
export const ModifiedBy = 'ModifiedBy';
/** The reason the record was last updated */
export const ChangeType = 'ChangeType';
/** True if athlete registration is a returning athlete */
export const ReturningAthlete = 'ReturningAthlete';
// Athlete fields below
export const Status = 'Status';
export const Team = 'Team';
export const Cox = 'Cox';
/** The original submission id */
export const Sid = 'Sid';
export const SubmitDate = 'SubmitDate';
export const CreateTimestamp = 'CreateTimestamp';
export const Program = 'Program';
export const ProgramYear = 'Year';
export const PaymentOption = 'PaymentOption';
export const First = 'First';
export const Last = 'Last';
export const Name = 'Name';
export const Address1 = 'Addr1';
export const Address2 = 'Addr2';
export const City = 'City';
export const State = 'State';
export const Zip = 'Zip';
export const Country = 'Country';
export const Gender = 'Gender';
export const Birthdate = 'Birthdate';
export const RowingAge = 'RowingAge';
export const Email = 'Email';
export const Phone = 'Phone';
export const School = 'School';
export const OtherSchool = 'OtherSchool';
export const HSGrad = 'HSGrad';
export const Allergies = 'Allergies';
export const MedCond = 'MedCond';
export const Meds = 'Meds';
export const ParentFirst = 'ParentFirst';
export const ParentLast = 'ParentLast';
export const ParentEmail = 'ParentEmail';
export const ParentPhone = 'ParentPhone';
export const ParentName = 'ParentName';
export const EmFirst = 'EmFirst';
export const EmLast = 'EmLast';
export const EmEmail = 'EmEmail';
export const EmPhone = 'EmPhone';
export const EmName = 'EmName';
export const EmRelationship = 'EmRelationship';
export const SecondaryFirst = 'SecondaryFirst';
export const SecondaryLast = 'SecondaryLast';
export const SecondaryEmail = 'SecondaryEmail';
export const SecondaryEmailList = 'SecondaryEmailList';
export const SecondaryPhone = 'SecondaryPhone';
export const SecondaryRelation = 'SecondaryRelation';
export const SecondaryName = 'SecondaryName';
export const Invoiced = 'Invoiced';
export const Balance = 'Balance';
export const BalanceDate = 'BalanceDate';
export const CovidWaiver = 'CovidWaiver';
export const CovidVaccine = 'CovidVaccine';
export const Waiver = 'Waiver';
export const USRMember = 'USRMember';
export const SwimTest = 'SwimTest';
export const Physical = 'Physical';
export const CodeOfConduct = 'CodeOfConduct';
export const PolicyAccept = 'PolicyAccept';
export const MastersPolicyAccept = 'MastersPolicyAccept';
export const Note = 'Note';
export const AthleteSafesport = 'AthleteSafesport';
export const ParentSafesport = 'ParentSafesport';

// Change types
export const ChangeImportCreate = 'createByImport';
export const ChangeCreate = 'create';
export const ChangeImport = 'import';
export const ChangeEdit = 'edit';
export const ChangeMerge = 'merge';
// List types
export const RegList = 'registrations';
export const MemberList = 'members';
export const NoList = 'none';
export const TimeSlot = 'TimeSlot';
export const Volunteering = 'Volunteering';
export const HowFound = 'HowFound';

export const QBOFirst = 'QBOFirst';
export const QBOLast = 'QBOLast';

/**
 * This constant is used to translate imported CSV to internal key names.
 */
export const NormalizedNames: { [key: string]: string } = {
  'Submission ID': Sid,
  'Submission Date': SubmitDate,
  // 'Amount',
  'Program Selection': Program,
  // 'Payment Option - Details',
  // 'Payment Option - Quantity',
  // 'Payment Option - Details',
  // 'Payment Option - Quantity',
  // 'Payment Option - Details',
  // 'Payment Option - Quantity',
  // 'Athlete Name - Prefix',
  'Name - First Name': First,
  'Name - Last Name': Last,
  'Athlete Name - First Name': First,
  'Athlete Name - Last Name': Last,
  'Address - Address Line 1': Address1,
  'Address - Address Line 2': Address2,
  'Address - City': City,
  'Address - State/Province': State,
  'Address - ZIP/Postal Code': Zip,
  'Address - Country': Country,
  'Cell Phone': Phone,
  // 'Athlete Name - Suffix',
  'Athlete Address - Address Line 1': Address1,
  'Athlete Address - Address Line 2': Address2,
  'Athlete Address - City': City,
  'Athlete Address - State/Province': State,
  'Athlete Address - ZIP/Postal Code': Zip,
  'Athlete Address - Country': Country,
  'Athlete Gender': Gender,
  'Athlete Birthdate': Birthdate,
  'Athlete Email': Email,
  'Athlete Cell Phone': Phone,
  'School Name': School,
  'Other High School Name': OtherSchool,
  'High School Graduation Year': HSGrad,
  'Rowing Experience': Team,
  Squad: Team,
  Allergies: Allergies,
  'Medical Conditions': MedCond,
  Medications: Meds,
  // 'Parent Name - Prefix',
  'Parent Name - First Name': ParentFirst,
  'Parent Name - Last Name': ParentLast,
  // 'Parent Name - Suffix',
  'Parent Cell Phone': ParentPhone,
  'Parent Email': ParentEmail,
  // 'Secondary Name - Prefix',
  'Secondary Name - First Name': SecondaryFirst,
  'Secondary Name - Last Name': SecondaryLast,
  // 'Secondary Name - Suffix',
  'Relationship to Athlete': SecondaryRelation,
  'Secondary Cell Phone': SecondaryPhone,
  'Secondary Email': SecondaryEmail,
  'Emergency Contact Name - First Name': EmFirst,
  'Emergency Contact Name - Last Name': EmLast,
  'Emergency Contact Phone': EmPhone,
  'Emergency Contact Email': EmEmail,
  Relationship: EmRelationship,
  'Time Slot': TimeSlot,
  'Volunteer options': Volunteering,
  // 'Policy Acceptance',
  // 'Is Recurring',
  // 'Payment Status'
};
