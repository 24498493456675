import React from 'react';
import { Snackbar, Icon } from '@mui/material';
import { useGlobalPropertyWithDefault } from './UseGlobalProperty';
import clsx from 'clsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import makeStyles from '@mui/styles/makeStyles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface ToastProps {
  open: boolean;
  message?: string;
  variant?: keyof typeof variantIcon;
}

export const Toast = () => {
  const classes = useStyles1();
  const [toast, setToast] = useGlobalPropertyWithDefault<ToastProps>('toast', {
    open: false,
  });

  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast({ open: false });
  };
  const onClose = () => setToast({ open: false });
  const variant = toast.variant || 'success';
  const message = toast.message || '';
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={toast.open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={clsx(classes[variant])}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose} size="large">
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

const useToast = () => {
  const [, setToast] = useGlobalPropertyWithDefault<ToastProps>('toast', {
    open: false,
  });
  return {
    success: (message: string) => setToast({ open: true, message, variant: 'success' }),
    failure: (message: string) => setToast({ open: true, message, variant: 'error' }),
  };
};
export default useToast;
