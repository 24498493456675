import { useGlobalPropertyWithDefault } from '../hooks/UseGlobalProperty';
export interface MemberInfo {
  id?: string;
  phones?: string[];
}

export enum AuthStep {
  Idle,
  QueryMemberExists,
  UserInput,
  WaitingForResopnse,
  QueryMemberDetails,
}

export const useRegistrationActiveStep = () => {
  return useGlobalPropertyWithDefault<number>('@reg-activeState', 0);
};

export const useRegistrationAuthStep = () => {
  return useGlobalPropertyWithDefault<AuthStep>('@reg-authState', AuthStep.Idle);
};

export const useRegistrationMemberInfo = () => {
  return useGlobalPropertyWithDefault<MemberInfo>('@reg-memberInfo', {});
};
