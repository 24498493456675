import { initializeApp } from 'firebase/app';
import firebase from 'firebase/compat/app';

export const firebaseConfig = {
  apiKey: 'AIzaSyCGezOD79m2pvo1yyFfFLeLx07qJ_yIYqQ',
  authDomain: 'era-rosters.firebaseapp.com',
  databaseURL: 'https://era-rosters.firebaseio.com',
  projectId: 'era-rosters',
  storageBucket: 'era-rosters.appspot.com',
  messagingSenderId: '512485824919',
  appId: '1:512485824919:web:d6f1c45ff7a4fa45',
};

// V9+ init
export const app = initializeApp(firebaseConfig);
// V8 compat init
firebase.initializeApp(firebaseConfig);
