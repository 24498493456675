import React from 'react';
import './App.css';
import HomePage from './HomePage';
import { AlertDialog } from './hooks/UseAlertDialog';
import { ThemeProvider, Theme, StyledEngineProvider, createTheme } from '@mui/material/styles';

import { GlobalProperty } from './hooks/UseGlobalProperty';
import EditRecord from './EditRecord';
import { Toast } from './hooks/UseToast';
import MergeAccounts from './MergeAccounts';
import FormEditRow from './util/formsx/FormEditRow';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme();
const App: React.FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalProperty>
          <HomePage />
          <AlertDialog />
          <EditRecord />
          <MergeAccounts />
          <Toast />
          <FormEditRow />
        </GlobalProperty>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
