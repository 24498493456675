module.exports = {
html: `<div>
  <div class="lgld-field lgld-input-rich-text full-width" id="field_62" data-bind="visible: field_62_visible">
    <div class="lgld-field-display" data-bind="visible: field_62_visible">
      <div class="lgld-rich-text-name"></div>
      <div class="lgld-rich-text-desc">
        <p>By clicking below I confirm I am over 18 and I agree to abide by the following:</p>
        <ul>
          <li>ERA Athlete Code of Conduct</li>
          <li>ERA Policies and Procedures</li>
          <li>ERA Emergency Medical Treatment Policy</li>
      <li>
        <COVID19 style="color: #ff0000;">COVID19 liability release</span>
      </li>
          <li>If signing up for recurring payments I agree to allow Everett Rowing to continue making payments while I have an
            account balance.
            In addition, I agree to update ERA with new credit card details if my card expires or is deactivated.
          </li>
        </ul>
        <p>
          These forms are available on our&nbsp;<a href="https://www.everettrowing.com/forms-policies">Forms an Policies</a>
          page.
        </p>
      </div>
    </div>
    <script type="text/javascript">
      formViewModel.field_62_visible = true;
      formViewModel.field_62_value = ko.observable('');
    </script>
  </div>
</div>
</div>`
}