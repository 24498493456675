import React, { useState } from 'react';
import { useDataListWriteOnly } from '../hooks/UseDataList';
import { useForm } from '../hooks/UseForm';
import { useRegistrationMemberInfo, useRegistrationActiveStep } from './RegistrationState';
import { ArgMap } from '../shared/Types';
import { RegistrationKeys, MemberKeys } from '../data/FieldTypes';
import * as Keys from '../shared/Keys';
import uuidv4 from 'short-uuid';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useRosterType } from '../data/RosterTypes';
import { Progress } from './Progress';
import { useGlobalPropertyWithDefault } from '../hooks/UseGlobalProperty';
import { getDateString } from '../util/RosterUtils';
import { useIsAdmin } from '../hooks/UseUserState';
import { usePaymentAmount } from '../hooks/UseMemberInfo';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
  items: {
    margin: theme.spacing(2),
  },
}));
export const ConfirmRegistration = () => {
  const classes = useStyles();
  const [rosterType] = useRosterType();
  const { update: updateRegistration } = useDataListWriteOnly(`${rosterType}/registrations`);
  const { update: updateMember } = useDataListWriteOnly(`${rosterType}/members`);
  const { values, extraValues } = useForm();
  const [memberInfo] = useRegistrationMemberInfo();
  const [submitting, setSubmitting] = useState<boolean>();
  const [sendError, setSendError] = useState('');
  const [isAdmin] = useIsAdmin();
  const [, setActiveStep] = useRegistrationActiveStep();
  const [, setPaymentLink] = useGlobalPropertyWithDefault('paymentLink', '');
  const [, setPaymentAmount] = usePaymentAmount();
  const okToProceed = Boolean(isAdmin || values[Keys.PolicyAccept] || values[Keys.MastersPolicyAccept]);
  const onSubmit = async () => {
    try {
      setSubmitting(true);
      let newMember = false;
      if (values[Keys.Program].includes('Middle')) {
        values[Keys.Team] = 'Middle School';
      }
      const member: ArgMap = {};
      MemberKeys.forEach((key) => {
        if (values[key] !== undefined) {
          member[key] = values[key];
        }
      });
      let memberId = memberInfo.id;
      if (!memberId) {
        memberId = uuidv4.generate();
        newMember = true;
      }
      member[Keys.Id] = memberId;
      // Store the 'last set' values for the next registration
      [Keys.School, Keys.OtherSchool].forEach((key) => {
        if (values[key] !== undefined) {
          member[key] = values[key];
        }
      });

      const registration: ArgMap = {};
      RegistrationKeys.forEach((key) => {
        if (values[key] !== undefined) {
          registration[key] = values[key];
        }
      });

      for (const page in extraValues) {
        const extra = extraValues[page];
        for (const key in extraValues[page]) {
          registration[key] = extra[key];
        }
      }

      const uuid = uuidv4.generate();
      registration[Keys.MemberId] = memberInfo.id;
      registration[Keys.Sid] = uuid;
      registration[Keys.Id] = uuid;
      registration[Keys.MemberId] = memberId;
      const now = Date.now();
      registration[Keys.SubmitDate] = getDateString();
      registration[Keys.CreateTimestamp] = now;
      const program: string = values[Keys.Program];
      // remove leading year if present and trailing cost in parens
      registration[Keys.Program] = program.replace(/^[0-9][0-9][0-9][0-9] */, '').replace(/ *\(.*/, '');

      let parts = program.split(' ');
      const year = parts[0];
      if (year.match(/^[0-9][0-9][0-9][0-9]/)) {
        registration[Keys.ProgramYear] = year;
      } else {
        const now = new Date();
        if (now.getMonth() === 11) {
          registration[Keys.ProgramYear] = String(now.getFullYear() + 1);
        } else {
          registration[Keys.ProgramYear] = String(now.getFullYear());
        }
      }
      let amount = '';
      if (values[Keys.PaymentOption]) {
        parts = values[Keys.PaymentOption].split(' ');
        amount = parts[0].replace('$', '').replace(',', '');
      } else {
        // extract amount from 'xxx Program ($1,080)'
        amount = program.replace(/^.*\$/, '').replace(/\).*/, '').replace(',', '');
      }
      // console.log('Updating member: ' + JSON.stringify(member));
      await updateMember(member, !newMember); // update=true if existing member

      console.log('Registration =' + JSON.stringify(registration));
      await updateRegistration(registration);

      console.log('Success!!');
      let url = 'https://secure.lglforms.com/form_engine/s/I7pf7alEXKawGMchcpFBDw';
      url += `?t=${Date.now()}`;
      url += `&field_30=${values[Keys.First]} ${values[Keys.Last]}`;
      url += `&field_31=${values[Keys.Program]}`;
      url += `&field_9=${values[Keys.ParentEmail] || values[Keys.Email]}`;
      url += `&field_6=${values[Keys.ParentFirst] || values[Keys.First]}`;
      url += `&field_7=${values[Keys.ParentLast] || values[Keys.Last]}`;
      //url += `&field_26=${amount}`;  // Added in Payment.tsx
      setPaymentAmount(Number(amount));
      setPaymentLink(url);
      setActiveStep((currentStep) => currentStep + 1);
    } catch (e) {
      setSendError(`Error ${String(e)}`);
      console.log(`update err: ${String(e)}`);
    }
  };

  return (
    <div>
      <Typography>You have selected the following options:</Typography>
      <div className={classes.items}>
        <Typography>
          Athlete: {values[Keys.First]} {values[Keys.Last]}
        </Typography>
        <Typography>{values[Keys.Program]}</Typography>
        <Typography>{values[Keys.PaymentOption]}</Typography>
        {sendError && <Typography color="error">{sendError}</Typography>}
        {!sendError && submitting ? (
          <Progress period={25000} />
        ) : (
          <Button
            disabled={!okToProceed}
            variant="contained"
            color="primary"
            onClick={onSubmit}
            className={classes.button}
          >
            Submit Registration
          </Button>
        )}
      </div>
    </div>
  );
};
export default ConfirmRegistration;
