import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  }
}));

export const AdminPage = () => {
  const classes = useStyles();
  return <div className={classes.root} />;
};
export default AdminPage;
