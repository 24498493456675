import React, { ReactNode, useState, useContext, useEffect, useRef } from 'react';
import { ArgMap } from './FormTypes';
const defaultFormContext = {
  values: {} as ArgMap,
  errors: {} as ArgMap,
  setValue: (key: string, value: string) => {},
  submit: () => {},
  changes: new Set<string>(),
  setDefaults: (defaults: ArgMap) => {},
  extraValues: {} as ArgMap<ArgMap>,
};
type FormContextType = typeof defaultFormContext;
type FormOnChange = (key: string, value: string) => boolean;
type FormOnSubmit = (values: ArgMap) => void;
const FormContext = React.createContext(defaultFormContext as FormContextType);

interface Props {
  defaultValues: ArgMap;
  errors: ArgMap;
  children?: ReactNode;
  onSubmit?: FormOnSubmit;
  onChange?: FormOnChange;
  formValues: ArgMap;
  extraValues?: ArgMap<ArgMap>;
  setFormValues: React.Dispatch<React.SetStateAction<ArgMap>>;
  setDefaults?: (defaults: ArgMap) => void;
}

export const FormData = (props: Props) => {
  const { children, errors, onChange, onSubmit, setDefaults, defaultValues } = props; //
  let { extraValues, formValues: values, setFormValues: setValues } = props; //useState({} as ArgMap);
  const [changes, setChanges] = useState(new Set<string>());
  if (!extraValues) {
    extraValues = defaultFormContext.extraValues;
  }

  let changeRef = useRef(changes); // Allow current value in useEffect
  useEffect(() => {
    // When defaults changes, create a new value set based
    // on the defaults and any changes that have been made.
    setValues((v) => {
      const mods = {} as ArgMap;
      changeRef.current.forEach((key) => (mods[key] = v[key]));
      return { ...defaultValues, ...mods };
    });
  }, [defaultValues, setValues]);

  const setValue = (name: string, value: string) => {
    let commit = true;
    if (onChange) {
      commit = onChange(name, value);
    }
    if (commit) {
      setValues((prev) => {
        if (prev[name] !== value) {
          setChanges((prev) => {
            prev.add(name);
            return prev;
          });
          return { ...prev, [name]: value };
        }
        return prev;
      });
    }
  };
  const submit = () => {
    if (onSubmit) {
      //console.log('Extra Values=' + JSON.stringify(extraValues));
      onSubmit(values);
    }
  };
  return (
    <FormContext.Provider
      value={{ values, extraValues, errors, setValue, changes, submit, setDefaults: setDefaults || (() => {}) }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useForm = () => {
  const ctx = useContext(FormContext);
  return ctx;
};
