module.exports = { html:`
<div class="lgld-field-display" data-bind="visible: field_65_visible">
  <div class="lgld-rich-text-name"></div>
  <div class="lgld-rich-text-desc">
    <p>
      <span style="color: #000000;">Everett Rowing offers several programs for adult rowers:</span>
    </p>
    <ul>
      <li>
        <span style="color: #000000;"
          >Annual Coached Program includes 12 months of instruction, ERA membership, and voting privileges.
          &nbsp;Payment can be annual, monthly, or quarterly.</span
        >
      </li>
      <li>
        <span style="color: #000000;"
          >Quarterly Coached Program includes 3 months of instruction but does not include ERA voting membership.</span
        >
      </li>
      <li>
        <span style="color: #000000;">Monthly Novice Program for new rowers during their first year of rowing.</span>
      </li>
      <li><span style="color: #000000;">Boat Storage for singles, doubles, and pairs.</span></li>
    </ul>
    <p>All Rowers must have</p>
    <ol>
      <li>Annual <strong>Printed USRowing Waiver</strong>&nbsp;on file with ERA</li>
      <li>Annual&nbsp;<strong>Electronic USRowing Waiver&nbsp;</strong></li>
      <li>One time completed <strong>Swim Test Form</strong></li>
      <li><COVID19 style="color: #ff0000;">COVID19 liability release</span></li>
    </ol>
    <p>
      These forms are available on our&nbsp;<a href="https://www.everettrowing.com/forms-policies">Forms an Policies</a>
      page.
    </p>
  </div>
</div>
` }
